import { Pipe, PipeTransform } from '@angular/core';
import {formatMinutestoHours} from '@app/utils/util';

@Pipe({
  name: 'formatSmbRegistryBusinessHours'
})
export class FormatSmbRegistryBusinessHoursPipe implements PipeTransform {

  constructor() {

  }

  transform(value: any, args?: any): any {
    if (value.closed) {
      return 'Closed';
    }

    if (value.open24Hours) {
      return 'Open 24hrs';
    }

    if ( value.openTime != null && value.closeTime != null) {
      return  `${formatMinutestoHours(value.openTime)} - ${formatMinutestoHours(value.closeTime)}`;
    }
  }
}