import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CurbsideService} from '@app/services/curbside/curbside.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private curbsideService: CurbsideService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    let modified = req.clone(
      {
        withCredentials: false
      }
    );
    if (this.curbsideService.cartSession.getValue()) {
      modified = modified.clone( {
        headers: req.headers.set('sccart', this.curbsideService.cartSession.getValue().id),
     });
    }
    req.headers.keys().forEach(key =>{
      console.log(key + ' '+req.headers.getAll(key));
    });
    return next.handle(modified);
  }
}
