import {ViewChild, Component, OnInit, Input, OnChanges, PLATFORM_ID, Inject} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

const staplesMarkerIcon = '../../assets/icon-staples-store-location.svg';
const staplesSmallMarkerIcon = '../../assets/location-small-marker-icon.svg';

@Component({
  selector: 'rde-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})

export class GoogleMapComponent implements OnInit, OnChanges {
  @ViewChild('googleMap', {static: true}) gmapElement: any;
  @Input() longitude = -34.397;
  @Input() latitude = 150.644;
  @Input() zoom = 15;
  @Input() zoomControl = false;
  @Input() showinfoWindow = false;
  @Input() markerImgSize = '';
  @Input() useStaplesMarker = true;
  @Input() styles = [];
  isBrowser: boolean;

  map: google.maps.Map;
  addressWindow: google.maps.InfoWindow;
  markerIcon: any;
  googleMapsGeocoder: any;
  latlng = { lat: this.latitude, lng: this.longitude };

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.initMap();
  }

  ngOnChanges(props) {
    if (props.longitude.previousValue !== props.longitude.currentValue ||
        props.latitude.previousValue !== props.latitude.currentValue ) {
      this.initMap();
    }
  }

  initMap() {
    if (this.isBrowser) {
      this.googleMapsGeocoder = new google.maps.Geocoder();
      this.addressWindow = new google.maps.InfoWindow();
      this.latlng = { lat: this.latitude, lng: this.longitude };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, {
        center: this.latlng,
        zoom: this.zoom,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        fullscreenControl: false,
        zoomControl: this.zoomControl,
        gestureHandling: this.zoomControl ? 'cooperative' : 'none',
        styles: this.styles
      });
      this.setMapMarkerWithAddress(this.latlng, this.map, this.showinfoWindow, this.markerImgSize);
    }
  }

  setMapMarkerWithAddress(latlng: any, map: any, showinfoWindow: boolean, markerImg: string) {
    const geocoder = new google.maps.Geocoder();
    const self = this;

    const marker = new google.maps.Marker({
      position: latlng,
      map,
      animation: google.maps.Animation.DROP,
      draggable: false,
      icon: this.markerIcon
    });

    if (this.useStaplesMarker) {
      this.markerIcon = {
        url: markerImg === 'small' ? staplesSmallMarkerIcon : staplesMarkerIcon,
        size: new google.maps.Size(40, 57),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 32)
      };
      marker.setIcon(this.markerIcon);
    }


    if (showinfoWindow) {
      geocoder.geocode({location: latlng}, (results) => {
        if (results[0]) {

          self.addressWindow = new google.maps.InfoWindow({
            content: results[0].formatted_address,
            maxWidth: 200
          });

          marker.addListener('click', () => {
            self.addressWindow.open(map, marker);
          });
        } else {
          self.handleLocationError(map.getCenter(), 'no stores found', map);
        }
      });
    }

    map.setCenter(latlng);
  }

  handleLocationError(pos: any, message: any, map: any) {
    const self = this;

    this.markerIcon = {
      url: staplesMarkerIcon,
      size: new google.maps.Size(40, 53),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 32)
    };
    const marker = new google.maps.Marker({
      position: pos,
      map,
      animation: google.maps.Animation.DROP,
      draggable: false,
      icon: this.markerIcon
    });

    this.addressWindow = new google.maps.InfoWindow({
      content: message,
      maxWidth: 200
    });

    map.setZoom(20);
    marker.addListener('click', () => {
      self.addressWindow.open(map, marker);
    });
  }
}
