import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { DataService } from '../data-service/data.service';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    private isBrowser: boolean;

    constructor(
        private http: HttpClient,
        private dataService: DataService,
        private cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    getNearByStoresList(lat, lng, mileRadius, limit) {
        let params = new HttpParams();

        params = params.append('latitude', lat);
        params = params.append('longitude', lng);
        params = params.append('radius', mileRadius || '150');
        params = params.append('limit', limit || '4');

        return this.http.get(environment.storeBaseURL, { params });
    }

    getStoreServices(featured?: boolean): Observable<Array<any>> {
        if (typeof featured !== 'undefined') {
            let params = new HttpParams();
            params = params.append('featuredService', String(featured));

            return this.http.get<Array<any>>(environment.storeBaseURL + '/service/', { params });
        }

        return this.http.get<Array<any>>(environment.storeBaseURL + '/service/');
    }

    getStoreServicesByStoreNumber(storeNumber: string): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.storeBaseURL}/service/getStoreServicesByStoreNumber/${storeNumber}`
        );
    }

    searchStoresByAddress(
        address: string,
        radius?: string,
        limit?: string
    ) {
        let params = new HttpParams();
        params = params.append('address', address);
        params = params.append('radius', radius || '150');
        params = params.append('limit', limit || '3');

        return this.http.get(`${environment.storeBaseURL}/`, { params });
    }

    searchStoresByCoordinate(
        lat: string,
        lng: string,
        radius?: string,
        limit?: string
    ) {
        let params = new HttpParams();
        params = params.append('latitude', lat);
        params = params.append('longitude', lng);
        params = params.append('radius', radius || '150');
        params = params.append('limit', limit || '3');

        return this.http.get(environment.storeBaseURL, { params });
    }

    searchStoresByServiceAddress(
        serviceId: string,
        address: string,
        radius?: string,
        limit?: string
    ) {
        let params = new HttpParams();

        params = params.append('storeServiceId', serviceId);
        params = params.append('address', address);
        params = params.append('radius', radius || '150');
        params = params.append('limit', limit || '3');

        return this.http.get(environment.storeBaseURL, { params });
    }

    searchStoresByServiceCoordinate(
        serviceId: string,
        lat: string,
        lng: string,
        radius?: string,
        limit?: string
    ) {
        let params = new HttpParams();

        params = params.append('storeServiceId', serviceId);
        params = params.append('latitude', lat);
        params = params.append('longitude', lng);
        params = params.append('radius', radius || '150');
        params = params.append('limit', limit || '3');

        return this.http.get(environment.storeBaseURL, { params });
    }

    searchStoresByValue(searchByValue) {
        let params = new HttpParams();

        params = params.append('address', searchByValue);
        params = params.append('radius', '150');
        params = params.append('limit', '3');

        return this.http.get(environment.storeBaseURL, { params });
    }

    getStoreByNumber(storeNumber: string) {
        return this.http.get(`${environment.storeBaseURL}/${storeNumber}`);
    }

    getStoreList() {
        return this.http.get('../../../assets/data/store-list.json');
    }

    getUserStore() {
        return this.dataService.selectedStore.getValue();
    }

    getUserStoreNumber() {
        return this.dataService.selectedStore?.getValue()?.storeNumber;
    }
}
