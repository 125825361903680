import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselSliderComponent } from './carousel-slider.component';
import { MaterialComponentsModule } from '../../material-components.module';

@NgModule({
  declarations: [
    CarouselSliderComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule
  ],
  exports: [
    CarouselSliderComponent
  ]
})

export class CarouselSliderModule { }
