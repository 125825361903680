import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { DynamicHTagComponent } from './dynamic-h-tag.component';
import { UtilsModule } from '@app/utils/utils.module';

@NgModule({
    declarations: [DynamicHTagComponent],
    imports: [
        CommonModule,
        UtilsModule,
        NgbCarouselModule,
        UtilsModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        RouterModule
    ],
    exports: [DynamicHTagComponent]
})
export class DynamicHTagModule {}
