import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeurl'
})
export class SafeurlPipe implements PipeTransform {

  constructor(private sr: DomSanitizer) { }

  transform(value: any, args?: any): any {
    return this.sr.bypassSecurityTrustHtml(value);
  }
}
