import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrePageComponent } from './bre-page/bre-page.component';
import { ClaimBusinessComponent } from '@app/auth/claim-business/claim-business.component';
import { environment } from '@env/environment';

const routes: Routes = [
    { path: 'claim-business', component: ClaimBusinessComponent },
    { path: '**', component: BrePageComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
