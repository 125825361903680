import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../material-components.module';
import { GoogleMapModule } from './google-map/google-map.module';
import { GoogleMapRouteComponent } from './google-map-route/google-map-route.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { AddressVerificationComponent } from './address-verification/address-verification.component';
import { ButtonModule } from '@staples-connect/component-library';
import { BadgesComponent } from './badges/badges.component';
import { ExpandableHoursComponent } from './expandable-hours/expandable-hours.component';
import { FormatSmbRegistryBusinessHoursPipe } from '@app/cms-components/small-business-registry/format-smb-registry-business-hours.pipe';
import { ListComponent } from './list/list.component';
import { UtilsModule } from '@app/utils/utils.module';
import { DynamicCtaComponent } from './dynamic-cta/dynamic-cta.component';
import { RouterModule } from '@angular/router';
import {DynamicHTagComponent} from '@app/cms-components/dynamic-h-tag/dynamic-h-tag.component';
import {PaginatorComponent} from '@app/rde-components/paginator/paginator.component';
import { SmbBreadcrumbsComponent } from './smb-breadcrumbs/smb-breadcrumbs.component';
@NgModule({
    declarations: [
      GoogleMapRouteComponent,
      SimpleDialogComponent,
      AddressVerificationComponent,
      ExpandableHoursComponent,
      FormatSmbRegistryBusinessHoursPipe,
      BadgesComponent,
      ListComponent,
      PaginatorComponent,
      SmbBreadcrumbsComponent],
    imports: [
      CommonModule,
      MaterialComponentsModule,
      ButtonModule,
      UtilsModule,
      RouterModule
    ],
    exports: [
      GoogleMapModule,
      GoogleMapRouteComponent,
      SimpleDialogComponent,
      AddressVerificationComponent,
      BadgesComponent,
      ExpandableHoursComponent,
      FormatSmbRegistryBusinessHoursPipe,
      ListComponent,
      PaginatorComponent,
      SmbBreadcrumbsComponent
    ]
})
export class RdeComponentsModule { }
