import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private rootUrl = '';

  constructor(private http: HttpClient) {
    this.rootUrl = environment.eventsRootUrl;
  }

  searchEvents(state: string, city: string, lat, lng) {
    let params = new HttpParams();

    if ( lat && lng ) {
      params = params.append('latitude', lat);
      params = params.append('longitude', lng);
    }

    city ? params = params.append('city', city) : '' ;
    state ? params = params.append('state', state) : '';

    params = params.append('publicEvent', 'true');

    return this.http.get(`${this.rootUrl}/search`, { params });
  }

  getEventById(id: string) {
    return this.http.get(`${this.rootUrl}/${id}`);
  }

  getStateAndCityList() {
    return this.http.get(`${this.rootUrl}/event-location-filter`);
  }

  downloadIcsFile(eventId) {
    return this.http.get(`${this.rootUrl}/${eventId}/ics`, {responseType: 'text'});
  }
}
