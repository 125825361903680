import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceCharacters'
})
export class ReplaceCharactersPipe implements PipeTransform {

  transform(value: string, find: string, replace: string): any {
    const regex = new RegExp(find, 'g');
    return value.replace(regex, replace );
  }

}
