import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmbDirectoryNearMePageModule } from './smb-directory-near-me-page/smb-directory-near-me-page.component';
import { MaterialComponentsModule } from '../../material-components.module';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '../../utils/utils.module';

import { RdeComponentsModule } from '../../rde-components/rde-components.module';
import { RemoveHttpFromUrlPipe } from './remove-http-from-url.pipe';
import { IdeasArticlePageModule } from '@app/cms-components/ideas-article-page-component/ideas-article-page.module';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { SmbRegistryBusinessDetailsPageModule } from '@app/cms-components/small-business-registry/smb-registry-business-details-page/smb-registry-business-details-page.component';

@NgModule({
    declarations: [RemoveHttpFromUrlPipe],

    imports: [
        CommonModule,
        MaterialComponentsModule,
        RouterModule,
        UtilsModule,
        RdeComponentsModule,
        IdeasArticlePageModule,
        NgbCarouselModule,
        SmbDirectoryNearMePageModule,
        SmbRegistryBusinessDetailsPageModule
    ]
})
export class SmallBusinessRegistryModule {}
