import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(@Inject(PLATFORM_ID) private platformId: any) {}

    handleError(error: any) {
        if (isPlatformBrowser(this.platformId)) {
            this.handleClient(error);
        } else {
            this.handleServer(error);
        }
    }

    handleClient(error: any) {
        console.error(error);
    }

    handleServer(error: any) {
        // This error is from CMS but does impact anything
        if (
            error.message.includes(
                'No matching bindings found for serviceIdentifier: Symbol(CmsEventBusService)'
            )
        ) {
            return;
        }
        console.error(error);
    }
}
