export class SEO {
  public title: string;
  public description: string;
  public author: string;
  public keywords: string;
  public image: string;
  public type: string;
  public ogDescription: string;
  public noindex: boolean;
  
  constructor() {
  }

}
