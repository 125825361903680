import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixIdeasUrl'
})
export class FixIdeasUrlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value.replace('/blog', '/articles');

    if (typeof window !== 'undefined'){
      if(window.location.href.includes('/stores/') && !value.startsWith('/stores')){
          value = '/stores' + value;
      }
    }


    return value.replace('/site', '');
  }
}
