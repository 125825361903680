import { Inject, Injectable, SecurityContext } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class CanonicalService {
    constructor(
        @Inject(DOCUMENT) private dom,
        @Inject(Location) private location,
        private sanitizer: DomSanitizer
    ) {}

    createCanonicalURL() {
        let element: HTMLLinkElement = this.dom.querySelector("link[rel='canonical']") || null;

        if (element == null) {
            element = this.dom.createElement('link') as HTMLLinkElement;
            this.dom.head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');

        let dnsName = environment.siteDNSName;

        if (this.dom.location.origin.includes('lb') || this.dom.location.origin.includes('staples.com')) {

            dnsName = environment.siteDNSNameStaples;
        }

        let path = this.location.path().split('?')[0];
        if(!path.startsWith('/stores')) {
          path = '/stores' + path;
        }
        element.setAttribute(
            'href',
            dnsName +
                this.sanitizer.sanitize(
                    SecurityContext.HTML,
                    this.sanitizer.bypassSecurityTrustHtml(path)
                )
        );
    }
}
