import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdeasService {

  private rootUrl = '';

  constructor(
    private http: HttpClient,
  ) {
      this.rootUrl = environment.ideasRootUrl;

  }

  getIdeasPageUri(uri: string) {
    return this.http.get(this.rootUrl + '/site/api-rde' + uri);
  }

  getCategories() {
    return this.http.get(this.rootUrl + '/site/api-rde/ideas/categories');
  }
}
