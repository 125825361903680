<ng-template #navAccordion let-linkData="link">
    <div class="small-expansion-header">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'16px'" [expandedHeight]="'16px'">
                    <span class="small-acc-header">{{ linkData.name }}</span>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let childItem of linkData.childMenuItems">
                    <ng-container
                        *ngIf="childItem.childMenuItems.length > 0"
                        [ngTemplateOutlet]="navAccordion"
                        [ngTemplateOutletContext]="{ link: childItem }"
                    ></ng-container>
                    <ng-container *ngIf="childItem.childMenuItems.length === 0">
                        <div class="small-expansion-terminal">
                            <a
                                (click)="toggleSide()"
                                *ngIf="childItem?._links?.site?.type === 'internal'"
                                [href]="childItem?._links?.site?.href | fixIdeasUrl"
                            >
                                {{ childItem.name }}
                            </a>
                            <a
                                (click)="toggleSide()"
                                *ngIf="childItem?._links?.site?.type === 'external'"
                                [target]="
                                    childItem?._links?.site?.type === 'external' ? '_blank' : ''
                                "
                                href="{{ childItem._links.site.href }}"
                            >
                                {{ childItem.name }}
                            </a>
                            <a
                                (click)="toggleSide()"
                                *ngIf="
                                    childItem?._links?.site?.type !== 'external' &&
                                    childItem?._links?.site?.type !== 'internal'
                                "
                            >
                                {{ childItem.name }}
                            </a>
                        </div>
                    </ng-container>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>

<span *ngFor="let menuItem of menuItems" class="mobile-nav">
    <ng-container *ngIf="menuItem.childMenuItems.length === 0; else accordion">
        <mat-list role="list">
            <a
                (click)="toggleSide()"
                *ngIf="
                    menuItem?._links?.site?.type === 'internal' &&
                    menuItem?.parameters['Menu Group'] !== 'right'
                "
                [href]="menuItem?._links?.site?.href | fixIdeasUrl"
                [target]="menuItem?._links?.site?.type === 'external' ? '_blank' : ''"
                routerLinkActive="active"
            >
                <ul class="listStyling">
                    <mat-list-item class="menuItems" role="listitem">{{
                        menuItem.name
                    }}</mat-list-item>
                </ul>
            </a>
        </mat-list>
        <mat-list class="items" role="list">
            <a
                (click)="toggleSide()"
                *ngIf="
                    menuItem?._links?.site?.type === 'external' &&
                    menuItem?.parameters['Menu Group'] !== 'right'
                "
                [target]="menuItem?._links?.site?.type === 'external' ? '_blank' : ''"
                href="{{ menuItem?._links?.site?.href }}"
            >
                <ul class="listStyling">
                    <mat-list-item class="menuItems" role="listitem">{{
                        menuItem.name
                    }}</mat-list-item>
                </ul>
            </a>
        </mat-list>
    </ng-container>
    <ng-template #accordion>
        <mat-accordion *ngIf="menuItem?.parameters['Menu Group'] !== 'right'">
            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40x'" [expandedHeight]="'40px'">
                    <mat-panel-title>
                        {{ menuItem.name }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!--    <a (click)="toggleSide()"-->
                <!--       *ngIf=" menuItem?._links?.site?.type === 'internal' && menuItem?._links?.site?.href"-->
                <!--       [href]="menuItem?._links?.site?.href | fixIdeasUrl"-->
                <!--    >-->
                <!--      {{menuItem.name | titlecase }}-->
                <!--    </a>-->
                <!--    <a (click)="toggleSide()"-->
                <!--       *ngIf="menuItem?._links?.site?.type === 'external' && menuItem?._links?.site?.href"-->
                <!--       [target]="menuItem?._links?.site?.type === 'external' ? '_blank' : ''"-->
                <!--       href={{menuItem._links.site.href}}>-->
                <!--      {{menuItem.name | titlecase}}-->
                <!--    </a>-->
                <ng-container *ngFor="let childItem of menuItem.childMenuItems">
                    <ng-container
                        *ngIf="childItem.childMenuItems.length > 0"
                        [ngTemplateOutlet]="navAccordion"
                        [ngTemplateOutletContext]="{ link: childItem }"
                    ></ng-container>
                    <ng-container *ngIf="childItem.childMenuItems.length === 0">
                        <div class="small-expansion-terminal">
                            <a
                                (click)="toggleSide()"
                                *ngIf="childItem?._links?.site?.type === 'internal'"
                                [href]="childItem?._links?.site?.href | fixIdeasUrl"
                            >
                                {{ childItem.name }}
                            </a>
                            <a
                                (click)="toggleSide()"
                                *ngIf="childItem?._links?.site?.type === 'external'"
                                [target]="
                                    childItem?._links?.site?.type === 'external' ? '_blank' : ''
                                "
                                href="{{ childItem._links.site.href }}"
                            >
                                {{ childItem.name }}
                            </a>
                            <a
                                (click)="toggleSide()"
                                *ngIf="
                                    childItem?._links?.site?.type !== 'external' &&
                                    childItem?._links?.site?.type !== 'internal'
                                "
                            >
                                {{ childItem.name }}
                            </a>
                        </div>
                    </ng-container>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
</span>
<hr class="divider" />
<span *ngFor="let menuItem of menuItems" class="mobile-nav highlavel-nav">
    <ng-container *ngIf="menuItem.childMenuItems.length === 0; else accordion">
        <mat-list
            class="items"
            role="list"
            *ngIf="
                (menuItem?._links?.site?.type === 'internal' &&
                    menuItem?.parameters['Menu Group'] == 'right') ||
                (menuItem?._links?.site?.type === 'external' &&
                    menuItem?.parameters['Menu Group'] === 'right')
            "
        >
            <a
                (click)="toggleSide()"
                *ngIf="
                    menuItem?._links?.site?.type === 'internal' &&
                    menuItem?.parameters['Menu Group'] == 'right'
                "
                [href]="menuItem?._links?.site?.href | fixIdeasUrl"
                [target]="menuItem?._links?.site?.type === 'external' ? '_blank' : ''"
                class="shop-now"
                mat-button
            >
                <ul class="listStyling">
                    <mat-list-item class="menuItems" role="listitem">
                        <mat-icon
                            *ngIf="menuItem?.parameters?.Icon"
                            class="shopping-bag {{ menuItem?.parameters.Icon }}"
                            svgIcon="{{ menuItem?.parameters.Icon }}"
                        ></mat-icon>
                        <span class="d-block ml-2">
                            <span
                                *ngIf="menuItem?.parameters['Eyebrow Text']"
                                class="eyebrow-text"
                                >{{ menuItem?.parameters['Eyebrow Text'] }}</span
                            >
                            <span class="font-weight-medium">{{ menuItem.name }}</span>
                        </span>
                    </mat-list-item>
                </ul>
            </a>

            <a
                *ngIf="
                    menuItem?._links?.site?.type === 'external' &&
                    menuItem?.parameters['Menu Group'] === 'right'
                "
                [target]="menuItem?._links?.site?.type === 'external' ? '_blank' : ''"
                class="shop-now"
                href="{{ menuItem._links.site.href }}"
                mat-button
            >
                <ul class="listStyling">
                    <mat-list-item class="menuItems" role="listitem">
                        <mat-icon
                            *ngIf="menuItem?.parameters?.Icon"
                            class="shopping-bag {{ menuItem?.parameters.Icon }}"
                            svgIcon="{{ menuItem?.parameters.Icon }}"
                        ></mat-icon>
                        <span class="d-block ml-2">
                            <span
                                *ngIf="menuItem?.parameters['Eyebrow Text']"
                                class="eyebrow-text"
                                >{{ menuItem?.parameters['Eyebrow Text'] }}</span
                            >
                            <span class="font-weight-medium">{{ menuItem.name }}</span>
                        </span>
                    </mat-list-item>
                </ul>
            </a>
        </mat-list>
        <mat-expansion-panel
            class="mat-elevation-z0"
            *ngIf="
                !menuItem?._links?.site &&
                    menuItem?.parameters['Special Key'] == 'account' &&
                    isAuthenticated;
                else loginLinkTmpl
            "
        >
            <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                <mat-panel-title>
                    <mat-icon
                        *ngIf="menuItem?.parameters?.Icon"
                        class="shopping-bag {{ menuItem?.parameters.Icon }}"
                        svgIcon="{{ menuItem?.parameters.Icon }}"
                    ></mat-icon>
                    <span class="d-block ml-2">
                        <span *ngIf="menuItem?.parameters['Eyebrow Text']" class="eyebrow-text">{{
                            menuItem?.parameters['Eyebrow Text']
                                | accountEyebrow: isAuthenticated:userData
                        }}</span>
                        <span class="font-weight-medium">{{ menuItem.name }}</span>
                    </span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngFor="let childItem of menuItem.childMenuItems">
                <a
                    (click)="toggleSide()"
                    *ngIf="childItem?._links?.site?.type === 'internal'"
                    [href]="childItem?._links?.site?.href | fixIdeasUrl"
                >
                    {{ childItem.name }}
                </a>
                <a
                    (click)="toggleSide()"
                    *ngIf="childItem?._links?.site?.type === 'external'"
                    [target]="childItem?._links?.site?.type === 'external' ? '_blank' : ''"
                    href="{{ childItem._links.site.href }}"
                >
                    {{ childItem.name }}
                </a>
            </ng-container>
            <a (click)="toggleSide()" class="logout pl-3"><span>Log out</span></a>
        </mat-expansion-panel>
        <ng-template #loginLinkTmpl>
            <mat-list
                class="items"
                role="list"
                *ngIf="
                    !menuItem?._links?.site &&
                    menuItem?.parameters['Special Key'] == 'account' &&
                    !isAuthenticated
                "
            >
                <a (click)="toggleSide()" class="shop-now" mat-button>
                    <ul class="listStyling">
                        <mat-list-item class="menuItems" role="listitem">
                            <mat-icon
                                *ngIf="menuItem?.parameters?.Icon"
                                class="shopping-bag account-icon {{ menuItem?.parameters.Icon }}"
                                svgIcon="{{ menuItem?.parameters.Icon }}"
                            ></mat-icon>
                            <span class="d-block ml-2">
                                <span
                                    *ngIf="menuItem?.parameters['Eyebrow Text']"
                                    class="eyebrow-text"
                                    >{{
                                        menuItem?.parameters['Eyebrow Text']
                                            | accountEyebrow: isAuthenticated:userData
                                    }}</span
                                >
                                <span class="font-weight-medium">{{ menuItem.name }}</span>
                            </span>
                        </mat-list-item>
                    </ul>
                </a>
            </mat-list>
        </ng-template>
    </ng-container>
    <ng-template #accordion>
        <mat-accordion class="nav-accordion" *ngIf="menuItem?.parameters['Menu Group'] == 'right'">
            <mat-expansion-panel
                class="mat-elevation-z0"
                *ngIf="menuItem?._links?.site && menuItem?.parameters['Special Key'] !== 'account'"
            >
                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                    <mat-panel-title>
                        <mat-icon
                            *ngIf="menuItem?.parameters?.Icon"
                            class="shopping-bag {{ menuItem?.parameters.Icon }}"
                            svgIcon="{{ menuItem?.parameters.Icon }}"
                        ></mat-icon>
                        <span class="d-block ml-2">
                            <span
                                *ngIf="menuItem?.parameters['Eyebrow Text']"
                                class="eyebrow-text"
                                >{{ menuItem?.parameters['Eyebrow Text'] }}</span
                            >
                            <span class="font-weight-medium">{{ menuItem.name }} </span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <a
                    (click)="toggleSide()"
                    *ngIf="
                        menuItem?._links?.site?.type === 'internal' && menuItem?._links?.site?.href
                    "
                    [href]="menuItem?._links?.site?.href | fixIdeasUrl"
                >
                    {{ menuItem.name }}
                </a>
                <a
                    *ngIf="
                        menuItem?._links?.site?.type === 'external' && menuItem?._links?.site?.href
                    "
                    [target]="menuItem?._links?.site?.type === 'external' ? '_blank' : ''"
                    href="{{ menuItem._links.site.href }}"
                >
                    {{menuItem.name}</a
                >
                <ng-container *ngFor="let childItem of menuItem.childMenuItems">
                    <a
                        (click)="toggleSide()"
                        *ngIf="childItem?._links?.site?.type === 'internal'"
                        [href]="childItem?._links?.site?.href | fixIdeasUrl"
                    >
                        {{ childItem.name }}
                    </a>
                    <a
                        *ngIf="childItem?._links?.site?.type === 'external'"
                        [target]="childItem?._links?.site?.type === 'external' ? '_blank' : ''"
                        href="{{ childItem._links.site.href }}"
                    >
                        {{ childItem.name }}
                    </a>
                </ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel
                class="mat-elevation-z0"
                *ngIf="!menuItem?._links?.site && !menuItem?.parameters['Special Key']"
            >
                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                    <mat-panel-title>
                        <mat-icon
                            *ngIf="menuItem?.parameters?.Icon"
                            class="shopping-bag {{ menuItem?.parameters.Icon }}"
                            svgIcon="{{ menuItem?.parameters.Icon }}"
                        ></mat-icon>
                        <span class="d-block ml-2">
                            <span
                                *ngIf="menuItem?.parameters['Eyebrow Text']"
                                class="eyebrow-text"
                                >{{ menuItem?.parameters['Eyebrow Text'] }}</span
                            >
                            <span class="font-weight-medium">{{ menuItem.name }} </span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let childItem of menuItem.childMenuItems">
                    <a
                        (click)="toggleSide()"
                        *ngIf="childItem?._links?.site?.type === 'internal'"
                        [href]="childItem?._links?.site?.href | fixIdeasUrl"
                    >
                        {{ childItem.name }}
                    </a>
                    <a
                        *ngIf="childItem?._links?.site?.type === 'external'"
                        [target]="childItem?._links?.site?.type === 'external' ? '_blank' : ''"
                        href="{{ childItem._links.site.href }}"
                    >
                        {{ childItem.name }}
                    </a>
                </ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel
                class="mat-elevation-z0"
                *ngIf="
                    !menuItem?._links?.site &&
                        menuItem?.parameters['Special Key'] == 'account' &&
                        isAuthenticated;
                    else loginLink
                "
            >
                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                    <mat-panel-title>
                        <mat-icon
                            *ngIf="menuItem?.parameters?.Icon"
                            class="shopping-bag {{ menuItem?.parameters.Icon }}"
                            svgIcon="{{ menuItem?.parameters.Icon }}"
                        ></mat-icon>
                        <span class="d-block ml-2">
                            <span
                                *ngIf="menuItem?.parameters['Eyebrow Text']"
                                class="eyebrow-text"
                                >{{
                                    menuItem?.parameters['Eyebrow Text']
                                        | accountEyebrow: isAuthenticated:userData
                                }}</span
                            >
                            <span class="font-weight-medium">{{ menuItem.name }}</span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-container *ngFor="let childItem of menuItem.childMenuItems">
                    <a
                        (click)="toggleSide()"
                        *ngIf="childItem?._links?.site?.type === 'internal'"
                        [href]="childItem?._links?.site?.href | fixIdeasUrl"
                    >
                        {{ childItem.name }}
                    </a>
                    <a
                        (click)="toggleSide()"
                        *ngIf="childItem?._links?.site?.type === 'external'"
                        [target]="childItem?._links?.site?.type === 'external' ? '_blank' : ''"
                        href="{{ childItem._links.site.href }}"
                    >
                        {{ childItem.name }}
                    </a>
                </ng-container>
            </mat-expansion-panel>
            <ng-template #loginLink>
                <mat-list
                    class="items"
                    role="list"
                    *ngIf="
                        !menuItem?._links?.site &&
                        menuItem?.parameters['Special Key'] == 'account' &&
                        !isAuthenticated
                    "
                >
                    <a (click)="toggleSide()" class="shop-now" mat-button>
                        <ul class="listStyling">
                            <mat-list-item class="menuItems" role="listitem">
                                <mat-icon
                                    *ngIf="menuItem?.parameters?.Icon"
                                    class="shopping-bag account-icon {{
                                        menuItem?.parameters.Icon
                                    }}"
                                    svgIcon="{{ menuItem?.parameters.Icon }}"
                                ></mat-icon>
                                <span class="d-block ml-2">
                                    <span
                                        *ngIf="menuItem?.parameters['Eyebrow Text']"
                                        class="eyebrow-text"
                                        >{{
                                            menuItem?.parameters['Eyebrow Text']
                                                | accountEyebrow: isAuthenticated:userData
                                        }}</span
                                    >
                                    <span class="font-weight-medium">{{ menuItem.name }}</span>
                                </span>
                            </mat-list-item>
                        </ul>
                    </a>
                </mat-list>
            </ng-template>
        </mat-accordion>
    </ng-template>
</span>

<div *ngIf="showStoreLocationFlyout && this.dataService.sideBarToggle | async">
    <div (click)="toggleStoreLocationFlyout()" class="back-to-menu-wrapper">
        <span class="left-arrow-icon"></span>
        <div class="back-to-menu-button">Back to Menu</div>
    </div>
    <rde-nav-location-fly-out></rde-nav-location-fly-out>
</div>
