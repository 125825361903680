import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  sendException(descriptionMessage: string, fatalValue: boolean) {
    // @ts-ignore
    // gtag('event', 'exception', {
    //   description: descriptionMessage,
    //   fatal: fatalValue
    // });
  }
}
