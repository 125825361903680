import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UtilsModule} from '@app/utils/utils.module';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {ProductOfferComponent} from '@app/cms-components/offer-block-two-offers/product-offer/product-offer.component';
import {OfferBlockTwoOffersComponent} from '@app/cms-components/offer-block-two-offers/offer-block-two-offers.component';
import {ButtonModule, ScCarouselModule} from '@staples-connect/component-library';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScAnimationsModule } from '@staples-connect/component-library';
import {RdeComponentsModule} from '@app/rde-components/rde-components.module';
import { DynamicCtaModule } from '@app/rde-components/dynamic-cta/dynamic-cta.module';
import { DynamicHTagModule } from '../dynamic-h-tag/dynamic-h-tag.module';
import { ProductOfferImageComponent } from './product-offer-image/product-offer-image.component';
import { ProductOfferVideoComponent } from './product-offer-video/product-offer-video.component';
import { GaTrackerDirective } from '@app/ga-tracker.directive';

@NgModule({
  declarations: [ProductOfferComponent, OfferBlockTwoOffersComponent, ProductOfferImageComponent, ProductOfferVideoComponent],
  //entryComponents: [ProductOfferComponent, OfferBlockTwoOffersComponent],
  exports: [ProductOfferComponent, OfferBlockTwoOffersComponent],
  imports: [
    CommonModule,
    UtilsModule,
    RouterModule,
    MatIconModule,
    ButtonModule,
    ScCarouselModule,
    BrowserAnimationsModule,
    ScAnimationsModule,
    RdeComponentsModule,
    DynamicCtaModule,
    DynamicHTagModule,
    GaTrackerDirective
  ]
})
export class OfferBlockTwoOffersModule { }
