import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatSelfServiceBusinessHours'
})
export class FormatSelfServiceHoursPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value.closed) {
      return 'Closed';
    }

    if (value.open24Hours) {
      return 'Open 24hrs';
    }

    if ( value.openTime != null && value.closeTime != null){
      return  `${this.formatTime(value.openTime)} - ${this.formatTime(value.closeTime)}`;
    }
  }

  formatTime(min: number): string {
    const hours = (min / 60);
    let rhours = Math.floor(hours);
    let amPm = rhours > 11 ? 'pm' : 'am';
    const minutes = (hours - rhours) * 60;
    while (rhours > 12) {
      rhours = rhours - 12;
    }
    const rminutes = Math.round(minutes);
    if (rhours === 0 ) {
      rhours = 12;
    }
    if (min === 1440) {
      amPm = 'am';
    }
    return `${rhours.toString().padStart(2, '0')}:${rminutes.toString().padStart(2, '0')}${amPm}`;
  }
}
