import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdeasArticlePageComponent } from './ideas-article-page/ideas-article-page.component';
import { IdeasArticlePageHeaderComponent } from './ideas-article-page-header/ideas-article-page-header.component';
import {SocialMediaButtonListComponent} from './social-media-button-list/social-media-button-list.component';
import {MaterialComponentsModule} from '../../material-components.module';
import { IdeasArticlePageBodyComponent } from './ideas-article-page-body/ideas-article-page-body.component';
import { IdeasArticlePageFooterComponent } from './ideas-article-page-footer/ideas-article-page-footer.component';
import { RelelatedArticlesComponent } from './relelated-articles/relelated-articles.component';
import {UtilsModule} from '../../utils/utils.module';
import {RouterModule} from '@angular/router';
import {IdeaCardMiniModule} from '@staples-connect/component-library';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

@NgModule({
    declarations: [
        IdeasArticlePageComponent,
        IdeasArticlePageHeaderComponent,
        SocialMediaButtonListComponent,
        IdeasArticlePageBodyComponent,
        IdeasArticlePageFooterComponent,
        RelelatedArticlesComponent    ],
    exports: [
        IdeasArticlePageComponent,
        SocialMediaButtonListComponent,
        RelelatedArticlesComponent,
        IdeasArticlePageFooterComponent,
    ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        MaterialComponentsModule,
        UtilsModule,
        IdeaCardMiniModule,
        RouterModule
    ]
})
export class IdeasArticlePageModule { }
