import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RDECarouselComponent } from './rdecarousel/rdecarousel.component';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import { RDECarouselVideoBoxComponent } from './rdecarousel-video-box/rdecarousel-video-box.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {RouterModule} from '@angular/router';
import { ScCarouselModule } from '@staples-connect/component-library';
import { UtilsModule } from '@app/utils/utils.module';
import { DynamicCtaModule } from '@app/rde-components/dynamic-cta/dynamic-cta.module';

@NgModule({
    declarations: [RDECarouselComponent, RDECarouselVideoBoxComponent],
    imports: [
        CommonModule,
        NgbCarouselModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        RouterModule,
        ScCarouselModule,
        UtilsModule,
        DynamicCtaModule
    ],
    exports: []
})
export class RDECarouselModule { }
