import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../../material-components.module';
import { UtilsModule } from '../../utils/utils.module';
import { RouterModule } from '@angular/router';
import { ServiceOfferingListComponent } from './service-offering-list/service-offering-list.component';
import { ServiceOfferingItemComponent } from './service-offering-item/service-offering-item.component';
import {ButtonModule} from '@staples-connect/component-library';

@NgModule({
    declarations: [
        ServiceOfferingListComponent,
        ServiceOfferingItemComponent
    ],
    exports: [
        ServiceOfferingListComponent,
    ],
    imports: [
        CommonModule,
        MaterialComponentsModule,
        UtilsModule,
        RouterModule,
        ButtonModule
    ]
})
export class ServiceOfferingListModule { }
