import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StoreService } from '../store/store.service';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '../data-service/data.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserLocationService {
    private isBrowser: boolean;
    private googleLocateAPISub: Subscription;

    constructor(
        private http: HttpClient,
        private storeService: StoreService,
        private dataService: DataService,
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private gaService: GoogleAnalyticsService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    getCurrentUserLocation() {
        /**
         * Priority
         * 1. setstore url param
         * 2. YourStore Cookie
         * 3. Locate by IP if allowed
         * 4. Default Store
         */
        if (!this.isBrowser) {
            return;
        }

        const queryStoreNumber = this.getUrlParameter('setstore');
        if (queryStoreNumber) {
            this.storeService
                .getStoreByNumber(queryStoreNumber)
                .pipe(take(1))
                .subscribe((storeInfo: any) => {
                    if (storeInfo) {
                        this.dataService.selectedStore.next(storeInfo);
                        this.dataService.userLocation.next({
                            lng: storeInfo.location.x,
                            lat: storeInfo.location.y
                        });
                    } else {
                        this.setToDefaultStore();
                    }
                });
        } else {
            const yourStoreCookie = this.cookieService.get('YourStore');
            const zipcodeCookie = this.cookieService.get('zipcode');
            const permissionCookie = this.cookieService.get('cmapi_cookie_privacy');

            if (yourStoreCookie) {
                this.setUserStoreFromCookie(JSON.parse(yourStoreCookie));
            } else if (zipcodeCookie) {
                this.setUserStoreFromZipcode(zipcodeCookie);
            } else if (
                !permissionCookie ||
                permissionCookie.includes('2') ||
                permissionCookie.includes('3')
            ) {
                this.setUserStoreFromIP();
            } else {
                this.setToDefaultStore();
            }
        }
    }

    setToDefaultStore() {
        /*
         * Setting to default store (Store 0008)
         * */
        const lat = '42.30043';
        const lng = '-71.35845';
        this.dataService.userLocation.next({ lng, lat });
        this.storeService.getStoreByNumber(environment.defaultStore).subscribe((store: any) => {
            this.dataService.selectedStore.next(store);
        });
    }

    getCurrentUserLocationByIp() {
        const googleApiURL = encodeURI(
            `https://www.googleapis.com/geolocation/v1/geolocate?key=${environment.googleApiKey}`
        );

        return this.http.post(
            googleApiURL,
            { considerIp: true },
            { headers: { accept: 'application/json' } }
        );
    }

    getUserCoord() {
        return this.dataService.userLocation.getValue();
    }

    getNearByStores(lat, lng) {
        this.storeService
            .getNearByStoresList(lat, lng, 150, 4)
            .pipe(take(1))
            .subscribe(
                (stores: Array<any>) => {
                    if (this.isBrowser && stores && stores.length) {
                        this.dataService.selectedStore.next(stores[0]);
                    } else {
                        this.resetToChooseYourStore();
                    }
                },
                err => {
                    this.gaService.sendException(err.message, false);
                    this.resetToChooseYourStore();
                }
            );
    }

    resetToChooseYourStore() {
        this.dataService.selectedStoreTitle.next('Choose Your Store');
        this.dataService.selectedStore.next(null);
        this.dataService.nearByStores.next(null);
    }

    getUrlParameter(name) {
        name = name.replace(/\[/, '\\[').replace(/\]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(location.search);

        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    setUserStoreFromCookie(storeCookie) {
        if (JSON.stringify(storeCookie) !== JSON.stringify({})) {
            this.storeService.getStoreByNumber(storeCookie.storeNumber).subscribe((store: any) => {
                this.dataService.selectedStore.next(store);
                this.dataService.userLocation.next({
                    lng: store.location.x,
                    lat: store.location.y
                });
            });
        }
    }

    setUserStoreFromZipcode(zipcode) {
        this.storeService.searchStoresByValue(zipcode).subscribe((storesList: any) => {
            if (storesList.length) {
                this.dataService.selectedStore.next(storesList[0]);
                this.dataService.userLocation.next({
                    lng: storesList[0].location.x,
                    lat: storesList[0].location.y
                });
            } else {
                return this.setToDefaultStore();
            }
        });
    }

    setUserStoreFromIP() {
        this.getCurrentUserLocationByIp()
            .pipe(take(1))
            .subscribe((userLoc: any) => {
                if (userLoc) {
                    this.storeService
                        .getNearByStoresList(userLoc.location.lat, userLoc.location.lng, 150, 3)
                        .subscribe((storesList: any) => {
                            if (storesList.length) {
                                this.dataService.selectedStore.next(storesList[0]);
                                this.dataService.userLocation.next({
                                    lng: storesList[0].location.x,
                                    lat: storesList[0].location.y
                                });
                            }
                        });
                } else {
                    return this.setToDefaultStore();
                }
            });
    }
}
