import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CurbsidePickupWrapperComponent} from './order-status-page/curbside-pickup-wrapper/curbside-pickup-wrapper.component';
import {CurbSidePickupOrderDetailsComponent} from './order-status-page/curb-side-pickup-order-details/curb-side-pickup-order-details.component';

import {RouterModule} from '@angular/router';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
// eslint-disable-next-line max-len
import {CurbsidePickupCustomerStatusButtonsComponent} from './order-status-page/curbside-pickup-customer-status-buttons/curbside-pickup-customer-status-buttons.component';
import {RdeComponentsModule} from '../../rde-components/rde-components.module';
import {MaterialComponentsModule} from '../../material-components.module';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';

import {UtilsModule} from '../../utils/utils.module';
import {CurbSidePickupProgressComponent} from './order-status-page/curb-side-pickup-progress/curb-side-pickup-progress.component';
import {CurbsidePickupOrderInformationComponent} from './order-status-page/curbside-pickup-order-information/curbside-pickup-order-information.component';
import {ButtonModule} from '@staples-connect/component-library';


@NgModule({
    declarations: [
        CurbsidePickupWrapperComponent,
        CurbSidePickupProgressComponent,
        CurbsidePickupOrderInformationComponent,
        CurbSidePickupOrderDetailsComponent,
        CurbsidePickupCustomerStatusButtonsComponent,
    ],
    exports: [
        CurbsidePickupWrapperComponent,
        CurbSidePickupProgressComponent,
        CurbsidePickupOrderInformationComponent,
        CurbSidePickupOrderDetailsComponent
    ],
    imports: [
        CommonModule,
        MaterialComponentsModule,
        RdeComponentsModule,
        RdeComponentsModule,
        UtilsModule,
        RouterModule,
        MatPaginatorModule,
        MatCheckboxModule,
        ButtonModule
    ]
})
export class CurbsidePickupModule {
}
