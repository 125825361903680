import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DataService } from '../../services/data-service/data.service';
import { isPlatformBrowser } from '@angular/common';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'rde-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
    @Input() component!: BrComponent;
    @Input() page: Page;
    content: any;
    configuration: any;
    // @Input() path: any;
    private isBrowser: boolean;
    menuItems: any;
    menuConfiguration: any;
    showStoreLocationFlyout = false;
    nearByStore = '';
    userLocationUpdate = false;
    isAuthenticated = false;
    userData: any;
    private notifier = new Subject();

    constructor(public dataService: DataService, @Inject(PLATFORM_ID) private platformId: any) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        // @ts-ignore
        this.getSiteMenuItems(this.component.getModels().menu);
        this.getMenuConfiguration();
        this.dataService.observenavFlyOutToggle.subscribe((res: boolean) => {
            this.showStoreLocationFlyout = res;
        });
        this.dataService.observeuserLocationUpdate.subscribe((res: boolean) => {
            this.userLocationUpdate = res;
        });
    }

    getSiteMenuItems(configuration): void {
        this.menuItems = configuration.siteMenuItems;
    }

    getMenuConfiguration(): void {
        // @ts-ignore
        this.menuConfiguration = this.component.getModels().menu;
    }

    toggleStoreLocationFlyout() {
        this.dataService.toggleNavFlyOut();
    }

    toggleSide(): void {
        this.dataService.toggleSideBar();
        this.dataService.navFlyOutToggle.next(false);
    }

    ngOnDestroy(): void {
        this.notifier.next(false);
        this.notifier.complete();
    }
}
