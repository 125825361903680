export const convert12HourTimeStringToIntegerString = (stdTime: string): string => {
  let integerTimeString = '';
  let x;
  if (stdTime.includes('AM')) {
    x = stdTime.replace(' AM', '');
    const y = x.split(':');
    const hourNum = parseInt(y[0], 10);
    const minNum = parseInt(y[1], 10);
    if (hourNum === 12) {
      integerTimeString = parseInt(y[1], 10).toString();
    } else {
      integerTimeString = ((hourNum * 60) + minNum).toString();
    }
  } else {
    x = stdTime.replace(' PM', '');
    const y = x.split(':');
    if (parseInt(y[0], 10) === 12) {
      const hour = parseInt(y[0], 10);
      integerTimeString = ((hour * 60) + parseInt(y[1], 10)).toString();
    } else {
      const hour = parseInt(y[0], 10) + 12;
      integerTimeString = ((hour * 60) + parseInt(y[1], 10)).toString();
    }
  }

  return integerTimeString;
};

export const removeNonNumericChars = (randomString: string): string | null => {
  let numericString = null;
  if (randomString) {
    numericString = randomString.replace(/\D/g, '');
  }
  return numericString;
};

export const formatMinutestoHours = (min: number): string => {
  const hours = (min / 60);
  let rhours = Math.floor(hours);
  let amPm = rhours > 11 ? 'PM' : 'AM';
  const minutes = (hours - rhours) * 60;
  rhours = rhours > 12 ? rhours - 12 : rhours;
  const rminutes = Math.round(minutes);
  if (rhours === 0 ) {
    rhours = 12;
  }
  if (min === 1440) {
    amPm = 'am';
  }
  return `${rhours}:${rminutes.toString().padStart(2, '0')} ${amPm}`;
};
