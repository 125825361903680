import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Registration} from '@app/models/smb-registry/Registration';
import {Observable} from 'rxjs';
import {DataService} from '@app/services/data-service/data.service';
import {Rewards} from '@app/models/Rewards';
import {Message} from '@app/models/smb-registry/Message';
import {LinkableMedia} from '@app/models/smb-registry/LinkableMedia';
import {BusinessInformation} from '@app/models/smb-registry/BusinessInformation';
import { Promo } from '@app/models/smb-registry/Promo';


@Injectable({
  providedIn: 'root'
})
export class SmbRegistryService {
  private rootUrl = '';
  private dotcomRootUrl = '';


  constructor(
    private http: HttpClient,
    private dataService: DataService)
  {
    this.rootUrl = environment.siteDNSName + '/api/small-business-registry/';
    this.dotcomRootUrl = environment.siteDNSNameStaples + '/stores/session/api/small-business-registry/'
  }

  getBusinessesNearMe(category: string, status: string, radius: number) {
    let params = new HttpParams();
    const store = this.dataService.selectedStore.getValue();

    if ( store.location && Object.keys(store.location).length) {
      params = params.append('latitude', store.location.y);
      params = params.append('longitude', store.location.x);
    }
    category ? params = params.append('category', category) : '' ;
    status ? params = params.append('status', status) : '';
    radius ? params = params.append('radius', radius.toString()) : '';

    return this.http.get(`${this.rootUrl}search`, {params});
  }

  postRegistration(registration, photos: Array<File>): Observable<any> {
    const formdata = new FormData();
    photos.forEach(photo => {
      formdata.append('files', photo);
    });
    formdata.append('publicRegistration', new Blob([JSON.stringify(registration)], {type: 'application/json'}));
    return this.http.post( this.dotcomRootUrl + 'registration', formdata);
  }

  postRewardsInfo(rewards: Rewards): Observable<any> {
    return this.http.post(this.rootUrl + 'rewards', rewards);
  }

  //deprecated
  postImage(images: any, id: string): Observable<any> {
    const payload = new FormData();
    payload.append('file', images);
    return this.http.post(this.rootUrl + 'registration/' + id + '/images',
      payload);
  }

  getFeaturedBusinesses(storeNumber: string): Observable<any> {
    const queryParams = new HttpParams()
      .set('page', '0')
      .set('size', '3');
    return this.http.get(this.rootUrl + 'community/' + storeNumber, {params: queryParams});
  }

  getStatuses(): Observable<any>{
    return this.http.get(this.rootUrl + 'status');
  }
  getReferralMethods(): Observable<any>{
    return this.http.get(this.rootUrl + 'referral-method');
  }
  getAdditionalSupport(): Observable<any>{
    return this.http.get(this.rootUrl + 'additional-support');
  }
  getCategories(): Observable<any>{
    return this.http.get(this.rootUrl + 'category');
  }

  getRegistryById(id): Observable<any> {
    return this.http.get(this.dotcomRootUrl + 'registration/' + id);
  }

  updateBusinessInfo(id: string, businessInfo: BusinessInformation) {
    return this.http.patch(this.dotcomRootUrl + 'registration/' + id + '/business-info', businessInfo);
  }

  updateMessage(id: string, message: Message): Observable<any> {
    return this.http.patch(this.dotcomRootUrl + 'registration/' + id + '/message', message);
  }

  updateLinkableMedia(id: string, linkableMedias: LinkableMedia[]): Observable<any> {
    return this.http.patch(this.dotcomRootUrl + 'registration/' + id + '/linkable-media', linkableMedias);
  }

  updateOperationalStatus(id: string, statuses: string[]): Observable<any> {
    return this.http.patch(this.dotcomRootUrl + 'registration/' + id + '/operational-status', statuses);
  }

  uploadImage(id: string, file) {
    const params = new FormData();
    params.append('file', file);
    return this.http.post(this.dotcomRootUrl + `registration/${id}/upload-business-image`, params)
  }

  updateBusinessImages(id: string, businessImages: string[]): Observable<any> {
    return this.http.patch(this.dotcomRootUrl + 'registration/' + id + '/business-images', businessImages);
  }

  updateBusinessHours(id: string, businessHours: any): Observable<any> {
    return this.http.patch(this.dotcomRootUrl + 'registration/' + id + '/business-hours', businessHours);
  }

  getLinkedBusinesses(email: string): Observable<any>{
    const queryParams = new HttpParams()
      .set('email', email);
    return this.http.get(this.rootUrl + 'link/getFromEmail', {params: queryParams});
  }

  getRegistrationsByUserId() {
    return this.http.get(this.dotcomRootUrl + 'registration/registrations');
  }

  public claimBusiness(id) {
    return this.http.patch(this.dotcomRootUrl + `registration/${id}/business-administrator/userid`, {});
  }

  public registerForService(id, services) {
    return this.http.patch(this.dotcomRootUrl + 'registration/' + id + '/opt-in', [services]);
  }

  public getGenericOptions(categoryName?: string) {
    let params = new HttpParams();
    if (categoryName) {
      params = params.append('categoryName', categoryName);
    }
    return this.http.get(this.rootUrl + 'optionmap/', {params});
  }

  patchGenericOptions(id: string, optionList: any) {
    return this.http.post(this.dotcomRootUrl + `registration/${id}/optionMaps/patch`, optionList);
  }

  postRegistrationProgress(reg: Registration) {
    return this.http.post(this.dotcomRootUrl + 'registration/progress', reg);
  }

  patchRegistrationImageProgress(images: Array<string>) {
    return this.http.patch(this.dotcomRootUrl + 'registration/progress/images', images);
  }

  getRegistrationProgress() {
    return this.http.get(this.dotcomRootUrl + 'registration/progress');
  }

  getDotComUserInfo(ignoreCache: boolean) {
    let queryParams = new HttpParams().set('ignorecache', ignoreCache);
    return this.http.get(this.dotcomRootUrl + 'registration/userinfo', {params: queryParams});
  }

  deleteByIdAndStatus(id: string, status: string) {
    let params = new HttpParams();
    if (status) {
      params = params.append('status', status);
    }
    return this.http.delete(this.dotcomRootUrl + 'registration/' + id, {params});
  }

  getBusinessDirectoryInfo(storeList: any[]) {
    return this.http.post(this.rootUrl + 'search/directory-listings', storeList);
  }

  getBusinessDirectoryInfoByGeoRadius(lat: number, long: number, radius: number, filters?: any) {
    return this.http.post(this.rootUrl + `directory?longitude=${long}&latitude=${lat}&radius=${radius}`, filters);
  }

  getBusinessDirectoryInfoByCommunity(state: string, city: string, filters?: any) {
    return this.http.post(this.rootUrl + `directory/${state}/${city}`, filters);
  }

  updatePromos(id: string, promos: Promo[]) {
    return this.http.patch(this.dotcomRootUrl + 'registration/' + id + '/promotion', promos);
  }

  aboutOwner (data: any, file?) {
    let formData = new FormData();
    formData.append('owner', new Blob([JSON.stringify(data)], {type: 'application/json'}));
    if (file) {
      formData.append('file', file);
    }
    return this.http.post(this.dotcomRootUrl + 'owner', formData);
  }

  updateAboutOwner(data: any, file?) {
    let formData = new FormData();
    formData.append('owner', new Blob([JSON.stringify(data)], {type: 'application/json'}));
    if (file) {
      formData.append('file', file);
    }
    return this.http.patch(this.dotcomRootUrl  + 'owner', formData);
  }

  getAboutOwner() {
    return this.http.get(this.dotcomRootUrl + 'owner/user');
  }
}
