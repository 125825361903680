<div class="store-location-flyout-wrapper" >
  <div class="max-out-wrapper">
    <div class="flyout-container">
      <div *ngIf="nearByStoreInfo" class="store-address-container">
        <div class="store-name">Staples,&nbsp;{{ nearByStoreInfo.address.city }} {{ nearByStoreInfo.address.region }}  </div>
        <div class="store-todays-availability" [innerHtml]="storeTimingStatus"></div>
        <div class="selected-store-address">
          <div>{{ nearByStoreInfo?.address?.address_1 + ' ' + nearByStoreInfo?.address?.address_2 }}</div>
          <div>{{ nearByStoreInfo?.address?.city + ', ' + nearByStoreInfo?.address?.region }}</div>
          <div>{{ nearByStoreInfo?.address?.postal_code }}</div>
          <div>{{ nearByStoreInfo?.phoneNumber | formatPhoneNumber }}</div>
        </div>

        <div class="store-timings">
          <span *ngIf="nearByStoreInfo?.storeHours">{{ nearByStoreInfo?.storeHours?.monday?.dayShort + ': ' + nearByStoreInfo?.storeHours?.weekday?.formattedStoreHours }}<br/></span>
          <span *ngIf="nearByStoreInfo?.storeHours">{{ nearByStoreInfo?.storeHours?.tuesday?.dayShort + ': ' + nearByStoreInfo?.storeHours?.weekday?.formattedStoreHours }}<br/></span>
          <span *ngIf="nearByStoreInfo?.storeHours">{{ nearByStoreInfo?.storeHours?.wednesday?.dayShort + ': ' + nearByStoreInfo?.storeHours?.weekday?.formattedStoreHours }}<br/></span>
          <span *ngIf="nearByStoreInfo?.storeHours">{{ nearByStoreInfo?.storeHours?.thursday?.dayShort + ': ' + nearByStoreInfo?.storeHours?.weekday?.formattedStoreHours }}<br/></span>
          <span *ngIf="nearByStoreInfo?.storeHours">{{ nearByStoreInfo?.storeHours?.friday?.dayShort + ': ' + nearByStoreInfo?.storeHours?.weekday?.formattedStoreHours }}<br/></span>
          <span *ngIf="nearByStoreInfo?.storeHours">{{ nearByStoreInfo?.storeHours?.saturday?.dayShort + ': ' + nearByStoreInfo?.storeHours?.saturday?.formattedStoreHours }}<br/></span>
          <span *ngIf="nearByStoreInfo?.storeHours">{{ nearByStoreInfo?.storeHours?.sunday?.dayShort + ': ' + nearByStoreInfo?.storeHours?.sunday?.formattedStoreHours }}<br/></span>
          <a class="view-details-link" [href]="storeDetailsLink" target="_blank">View Details</a>
        </div>
      </div>
      <div class="map-container" *ngIf="nearByStoreInfo">
        <rde-google-map markerImgSize="small" [longitude]="nearByStoreInfo?.location?.x" [latitude]="nearByStoreInfo?.location?.y"></rde-google-map>
      </div>
      <div class="location-search-wrapper">
        <div class="location-search-title">{{ nearByStoreInfo ? 'Change Location' : 'Choose Your Store' }}</div>
        <mat-form-field appearance="outline" class="search-location-input-field">
          <input matInput (keypress)="searchLocation($event)" (focusout)="searchLocationInMobile($event)" placeholder="Search city, state, zipcode" #searchField>
          <mat-icon (click)="searchLocation($event)">search</mat-icon>
          <mat-hint class="mat-hint-current-locaiton"><a class="currentLocationLink" (click)="useCurrentLocation()">Use Current Location</a></mat-hint>

        </mat-form-field>
    <p class="no-store-found" *ngIf="storesNotFoundMessage">{{storesNotFoundMessage}}</p>

        <div class="store-list-title" [class.no-stores]="!nearByStoreInfo">{{ nearByStoreInfo ? 'Other Stores Nearby ' : 'Stores Nearby ' }}
          <a class="view-all-stores" href="https://stores.staples.com" target="_blank">View All Stores</a>
        </div>
        <div class="store-list-wrapper" >
          <div class="stores" *ngFor="let store of stores">
            <div class="store">{{ store?.address?.address_1 + ' ' + store?.address?.address_2 }}</div>
            <div class="store">{{ store?.address?.city + ', ' + store?.address?.region + ' ' + store?.address?.postal_code }}</div>
            <div class="set-my-store" (click)="onSetMyStoreClick(store);">Set my store</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
