import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountEyebrow'
})
export class AccountEyebrowPipe implements PipeTransform {

  constructor(
  ) {
  }
  transform(value: string, isAuthenticated: boolean, userData: any ): string {
    const key = value.replace(/</g, '').replace(/>/g, '');
    if (isAuthenticated){
      let loginText = 'Hi, ' + userData?.given_name +  '!';
      if(loginText.length > 18){
        return loginText.substring(0,15)+ '...';
      }else{
        return 'Hi, ' + userData?.given_name +  '!';
      }
    } else {
      return 'Log in';
    }
  }

}
