import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "@env/environment";

@Injectable({
    providedIn: 'root'
})
export class RecycleService {
    constructor(private http: HttpClient) {}

    getRecycleStatistics(storeNumber: string): Observable<any> {
        const headers = new HttpHeaders().set(
            'Ocp-Apim-Subscription-Key',
            environment.recycleAPIKey
        );

        return this.http.get(environment.recycleAPIEndpoint + storeNumber, { headers });
    }
}
