import { v4 as uuid } from 'uuid';


export interface CreateCart {
  tenantId: string;
  cartType: string;
  contextId: string;
  requestId: string;
  requestTime: string;
  storeNumber: string;
  channel: string;
  clientContext: ClientContext;
  sellerId: string;
  userId: string;
}

export interface ClientContext {
  applicationType: string;
  ipAddress: string;
  applicationId: string;
}

export class CreateCartImpl implements CreateCart {
  cartType = 'REGULAR';
  channel = 'WEB';
  clientContext: ClientContext;
  contextId = '123';
  requestTime = '2020-04-30T19:34:17.746Z';
  requestId = '123';
  storeNumber: string;
  tenantId = 'STAPLESCONNECTWEB';
  sellerId: string;
  userId = '';
  currencyCode = 'USD';
  localeCode = 'US';
  storeAssociateId = 'web';

  constructor(storeNumber: string) {
    this.storeNumber = storeNumber;
    this.sellerId = storeNumber;
    this.requestId = uuid();
    this.contextId = uuid();
    // this.requestTime = Date.now().toString();
    this.clientContext = {
      applicationType: 'MOBILE',
      ipAddress: '0.0.0.0',
      applicationId: 'CUSTOMER_MOBILE'
    };
  }
}
