<div class="business-filters">
  <mat-form-field appearance="outline" class="business-filter" style="margin-left: 0px;">
    <mat-label>Business Type</mat-label>
    <mat-select  [(value)]="selectedCategory" (valueChange)="onFilterChange();" >
      <mat-option value="All" selected>All</mat-option>
      <mat-option *ngFor="let category of categories" [value]="category.name">
        {{ category.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="business-filter">
    <mat-label>Offerings</mat-label>
    <mat-select [(value)]="selectedStatus" (valueChange)="onFilterChange();">
      <mat-option value="All">All </mat-option>
      <mat-option *ngFor="let status of statuses" [value]="status.name">
        {{ status.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="business-filter">
    <mat-label>Distance</mat-label>
    <mat-select [(value)]="selectedRadius" (valueChange)="onFilterChange();"  >
      <mat-option value="5">5 Miles</mat-option>
      <mat-option value="10">10 Miles</mat-option>
      <mat-option value="25"  selected >25 Miles</mat-option>
      <mat-option value="50"  selected >50 Miles</mat-option>
      <mat-option value="100"  selected >100 Miles</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="spinner-wrapper" *ngIf="showLoader">
  <mat-spinner></mat-spinner>
</div>
<div class="events-body-wrapper" *ngIf="businesses && !showNoBusinesses" >
  <ng-container *ngFor="let state of businesses | keyvalue: originalOrder">
  <div class="store-wrapper" *ngFor="let city of state.value.cities | keyvalue: originalOrder">
        <rde-businesses-by-city [city]="city.value" [state]="state.value"></rde-businesses-by-city>
  </div>
  </ng-container>
    <div class="pagination-wrapper" *ngIf="totalPages > 1">
    <div class="pagination-container">
      <mat-icon *ngIf="indexOfFirstEvent !== 0" svgIcon="previous-button" class="previous-button" (click)="onPrevButtonClick();"></mat-icon>
      <div class="page-count">Page {{ currentPage }} of {{ totalPages }}</div>
      <mat-icon *ngIf="currentPage !== totalPages" svgIcon="next-button" class="next-button" (click)="onNextButtonClick();"></mat-icon>
    </div>
  </div>
</div>
<ng-container *ngIf="showNoBusinesses">
  <rde-no-business-near-you class="events-body-wrapper"></rde-no-business-near-you>
</ng-container>

