import { Component, Inject, Input, NgModule, OnInit } from '@angular/core';
import { DataService } from '@app/services/data-service/data.service';
import { SEO } from '@app/models/SEO';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { SmbBusinessPageHeaderComponent } from '@app/cms-components/small-business-registry/smb-registry-business-details-page/smb-business-page-header/smb-business-page-header.component';
import { MaterialComponentsModule } from '@app/material-components.module';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '@app/utils/utils.module';
import { RdeComponentsModule } from '@app/rde-components/rde-components.module';
import { SmbBusinessPageBodyComponent } from '@app/cms-components/small-business-registry/smb-registry-business-details-page/smb-business-page-body/smb-business-page-body.component';
import { IdeasArticlePageModule } from '@app/cms-components/ideas-article-page-component/ideas-article-page.module';

@Component({
    selector: 'rde-smb-registry-business-details-page',
    templateUrl: './smb-registry-business-details-page.component.html',
    styleUrls: ['./smb-registry-business-details-page.component.scss']
})
export class SmbRegistryBusinessDetailsPageComponent implements OnInit {
    @Input() component!: BrComponent;
    componentModels: any;
    page: Page;
    content: any;

    configuration: any;

    constructor(
        private dataService: DataService,
        @Inject('component') component: any | BrComponent,
        @Inject('page') page: any | Page
    ) {
        this.component = component;
        this.page = page;
    }
    ngOnInit() {
        this.componentModels = this.component.getModels();
        if (this.componentModels) {
            this.content = this.componentModels.business;
        }
        const seo = new SEO();
        seo.title = `Community Directory - ${this.content.publish.businessInformation.name}`;
        seo.description = `${this.content.publish.message.about}`;
        this.dataService.seoData.next(seo);
    }

    getContents(contentRef) {
        if (contentRef && contentRef.$ref) {
            this.content = this.page.getContent(contentRef).getData();
            if (this.content) {
                this.content.imageUrl = this.getImageUrl(this.content.image);
            }
        }
    }
    getImageUrl($ref): string {
        return this.page.getContent($ref).getUrl();
    }
}
@NgModule({
    declarations: [
        SmbRegistryBusinessDetailsPageComponent,
        SmbBusinessPageHeaderComponent,
        SmbBusinessPageBodyComponent
    ],
    exports: [SmbBusinessPageHeaderComponent, SmbBusinessPageBodyComponent],
    imports: [
        CommonModule,
        MaterialComponentsModule,
        RouterModule,
        UtilsModule,
        RdeComponentsModule,
        NgbCarouselModule,
        IdeasArticlePageModule
    ]
})
export class SmbRegistryBusinessDetailsPageModule {}
