import {Component, Input, OnInit} from '@angular/core';
import { mapStyling} from '@app/rde-components/google-map/map-styling';

@Component({
  selector: 'rde-smb-business-page-body',
  templateUrl: './smb-business-page-body.component.html',
  styleUrls: ['./smb-business-page-body.component.scss']
})
export class SmbBusinessPageBodyComponent implements OnInit {

  @Input() business: any;
  public noBody = false;
  addSupportArray = new Array<string>();
  constructor() { }
  mapStyle = mapStyling.style;
  ngOnInit() {
    if (!this.business.publish.message.about && !this.business.publish.message.appealToCommunity && !this.business.publish.message.tipsForOtherSmallBusinesses
    && (!this.business.publish.message.requestedMethodsOfSupport || this.business.publish.message.requestedMethodsOfSupport.length === 0)) {
      this.noBody = true;
    }

    if (this.business.publish.message.requestedMethodsOfSupport) {
      this.business.publish.message.requestedMethodsOfSupport.forEach(x => {
        this.addSupportArray.push(x.displayName);
      });
    }
  }

}
