/* eslint-disable max-len */
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    InjectionToken,
    OnDestroy,
    OnInit,
    Optional,
    PLATFORM_ID,
    Renderer2,
    ViewChild,
    makeStateKey
} from '@angular/core';
import { Page, PageModel } from '@bloomreach/spa-sdk';
import { Location, isPlatformBrowser } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer, TransferState } from '@angular/platform-browser';
import { BrPageComponent } from '@bloomreach/ng-sdk';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataService } from '../services/data-service/data.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { mapStyling } from '@app/rde-components/google-map/map-styling';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '@env/environment';
import { serialize } from 'cookie';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { CMSMappings } from '@app/bre-page/CMSMappings';

/* eslint-enable max-len */
export const BASE_URL = new InjectionToken<string>('SPA Base URL');
export const ENDPOINT = new InjectionToken<string>('brXM API endpoint');

@Component({
    selector: 'rde-bre-page',
    templateUrl: './bre-page.component.html',
    styleUrls: ['./bre-page.component.scss'],
    animations: [
        trigger('expandMenuAnimation', [
            state('false', style({ 'max-height': 0 })),
            state('true', style({ 'max-height': 1800 })),
            transition('false => true', [animate('1s ease-out')]),
            transition('true => false', [animate('1s ease-out')])
        ])
    ]
})
export class BrePageComponent implements OnInit, OnDestroy, AfterViewInit {
    page: Page;
    configuration: BrPageComponent['configuration'];
    title = 'Staples Connect';
    @ViewChild('topSection', { static: false }) elementView: ElementRef;
    @ViewChild('rightLocation', { static: false }) rightLocation: ElementRef;
    @ViewChild('bottomfooter', { static: false }) bottomfooter: ElementRef;

    private isBrowser: boolean;
    private apiUrls;

    showExpandMenu = false;

    sideBarToggleSub: Subscription;
    storeInfo: any;
    private navigationEnd: Observable<NavigationEnd>;
    mapStyle = mapStyling.style;
    selectedStore: any;
    // Use cms dns name if SSR
    cmsBaseUrl = environment.cmsBaseUrlSSR;
    mapping = CMSMappings.mapping;
    pageModel: PageModel;


    constructor(
        private sanitizer: DomSanitizer,
        private location: Location,
        public dataService: DataService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private renderer: Renderer2,
        private transferState: TransferState,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(BASE_URL) baseUrl?: string,
        @Inject(ENDPOINT) endpoint?: string,
        @Inject(REQUEST) @Optional() private request?: Request | any,
        @Inject(RESPONSE) @Optional() private response?: Response | any,
        @Inject('BR_PAGE_MODEL_DATA') @Optional() private brPagemodelData?: PageModel

    ) {
        this.pageModel = this.transferState.get(makeStateKey('BR_PAGE_MODEL_DATA_KEY'), null) ;
        this.isBrowser = isPlatformBrowser(platformId);
        if (this.isBrowser) {
            // Use dev proxy if client side.
            this.cmsBaseUrl = environment.cmsBaseUrlExternal;
        }
        // authorizationHeader: 'HST-Authorization',
        // authorizationQueryParameter: 'HST-Authorization',
        const currentPath = router.url;
        if (this.isBrowser) {
            if (currentPath.startsWith('/stores')) {
                this.renderer.addClass(
                    document.getElementById('staples-connect-margin-wrapper'),
                    'staples-connect-margin-wrapper'
                );
            }
        }
        this.configuration = {
            baseUrl,
            endpoint,
            endpointQueryParameter: 'brxm',
            cmsBaseUrl: this.cmsBaseUrl,
            origin: environment.cmsOrigin,
            authorizationHeader: 'HST-Authorization',
            authorizationQueryParameter: 'HST-Authorization',
            request: {
                connection: request?.connection,
                headers: this.setHeaders(request),
                path: this.cleanPath(currentPath)
            }
        } as BrPageComponent['configuration'];
        this.navigationEnd = router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ) as Observable<NavigationEnd>;
    }

    setHeaders(request) {
        const headers: any = {};

        if (request?.headers) {
            Object.keys(request.headers).forEach(key => {
                switch (key) {
                    case 'cookie':
                        // eslint-disable-next-line no-case-declarations
                        const a = 'scUserSelectedStore';
                        // eslint-disable-next-line no-case-declarations
                        const b = request.headers[key].match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
                        // eslint-disable-next-line no-case-declarations
                        const cookieValue = b ? JSON.parse(decodeURIComponent(b.pop())) : '';
                        headers.scUserStore = cookieValue.storeNumber;
                        break;
                    case 'X-FORWARDED-FOR':
                        headers[key] = request.headers[key];
                        break;
                    case 'true-client-ip':
                        headers[key] = request.headers[key];
                        break;
                    default:
                        headers[key] = request.headers[key];
                }
            });
        }

        return headers;
    }

    ngOnInit() {
        this.navigationEnd.subscribe(event => {
            this.configuration = {
                ...this.configuration,
                request: { path: this.cleanPath(event.url) }
            };
        });
    }

    cleanPath(pathToClean: string): string {
        if (pathToClean.startsWith('/stores')) {
            pathToClean = pathToClean.replace('/stores', '');
        }
        if (pathToClean.includes('#')) {
            pathToClean = pathToClean.split('#')[0];
        }

        return pathToClean;
    }

    ngOnDestroy(): void {
        if (this.sideBarToggleSub != null) {
            this.sideBarToggleSub.unsubscribe();

            // setting nav status to be false, to start nav animation on APP load / refresh.
            this.dataService.setNavLoadState(false);
        }
    }

    ngAfterViewInit(): void {
        if (this.page) {
            this.page.sync();
        }
    }
}
