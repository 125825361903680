<div class="store-events-container">
  <div class="store-events-wrapper">
    <h4 class="store-address bold">{{city.name}}, {{state.abbreviation}}</h4>
    <div class="store-events-body-row">
      <a *ngFor="let business of city.businesses; let i = index" class="event-card" [href]=" ['/small-business/directory/' + business.url +'.html'] " >
        <mat-card appearance="outlined">
          <img class="card-image" *ngIf="business.publish.businessInformation?.akamaiUrls" src={{business.publish.businessInformation?.akamaiUrls[0]}} [alt]="business.publish.businessInformation.name" />
          <div class="event-details">
            <div class="event-description">
              <h5 class="event-intro bold" >{{business.publish.businessInformation.name}}</h5>
              <div class="event-category">{{business.publish.businessInformation.category.displayName}}</div>
              <div class="multiline-ellipsis-overflow">
                <p class="venue-time-wrapper event-location body-text-3">
                  {{ business.publish.message.about}}
                </p>
              </div>
              <div class="address-wrapper" *ngIf="!business.publish.businessInformation.makeAddressPrivate">
                <span class="event-date body-text-3">{{ business.publish.businessInformation.address.address1}} {{ business.publish.businessInformation.address.address2}},</span>
                <span class="event-date body-text-3">{{ business.publish.businessInformation.address.city}}, {{ business.publish.businessInformation.address.state}} {{ business.publish.businessInformation.address.postalCode}}</span>
              </div>
            </div>
          </div>
        </mat-card>
      </a>
    </div>
    <a class="load-more-button link-down-arrow" (click)=onLoadMoreClick($event)>
      {{ 'View ' + eventsCountLeft + ' more businesses' }}
    </a>
  </div>
</div>
