<br-page [configuration]="configuration" [mapping]="mapping" [page]="pageModel">
  <ng-template let-page="page">
  <div class="content">
    <ng-container brComponent="main"></ng-container>
    <ng-container brComponent="loyalty-editable"></ng-container>
    <div class="help-center-wrapper">
      <ng-container brComponent="breadcrumb"></ng-container>
      <div class="help-center">
        <div class="help-center-left">
          <ng-container brComponent="left"></ng-container>
        </div>
        <div class="help-center-right">
          <ng-container brComponent="right"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading [show]="dataService.loaderSubject | async" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>
  </ng-template>
</br-page>
