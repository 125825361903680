export class Constants {
  public static STATES = [
    {shortName: 'AL', fullName: 'Alabama'},
    {shortName: 'AK', fullName: 'Alaska'},
    {shortName: 'AZ', fullName: 'Arizona'},
    {shortName: 'AR', fullName: 'Arkansas'},
    {shortName: 'CA', fullName: 'California'},
    {shortName: 'CO', fullName: 'Colorado'},
    {shortName: 'CT', fullName: 'Connecticut'},
    {shortName: 'DE', fullName: 'Delaware'},
    {shortName: 'DC', fullName: 'District of Columbia'},
    {shortName: 'FL', fullName: 'Florida'},
    {shortName: 'GA', fullName: 'Georgia'},
    {shortName: 'HI', fullName: 'Hawaii'},
    {shortName: 'ID', fullName: 'Idaho'},
    {shortName: 'IL', fullName: 'Illinois'},
    {shortName: 'IN', fullName: 'Indiana'},
    {shortName: 'IA', fullName: 'Iowa'},
    {shortName: 'KS', fullName: 'Kansas'},
    {shortName: 'KY', fullName: 'Kentucky'},
    {shortName: 'LA', fullName: 'Louisiana'},
    {shortName: 'ME', fullName: 'Maine'},
    {shortName: 'MD', fullName: 'Maryland'},
    {shortName: 'MA', fullName: 'Massachusetts'},
    {shortName: 'MI', fullName: 'Michigan'},
    {shortName: 'MN', fullName: 'Minnesota'},
    {shortName: 'MS', fullName: 'Mississippi'},
    {shortName: 'MO', fullName: 'Missouri'},
    {shortName: 'MT', fullName: 'Montana'},
    {shortName: 'NE', fullName: 'Nebraska'},
    {shortName: 'NV', fullName: 'Nevada'},
    {shortName: 'NH', fullName: 'New Hampshire'},
    {shortName: 'NJ', fullName: 'New Jersey'},
    {shortName: 'NM', fullName: 'New Mexico'},
    {shortName: 'NY', fullName: 'New York'},
    {shortName: 'NC', fullName: 'North Carolina'},
    {shortName: 'ND', fullName: 'North Dakota'},
    {shortName: 'OH', fullName: 'Ohio'},
    {shortName: 'OK', fullName: 'Oklahoma'},
    {shortName: 'OR', fullName: 'Oregon'},
    {shortName: 'PA', fullName: 'Pennsylvania'},
    {shortName: 'PR', fullName: 'Puerto Rico'},
    {shortName: 'RI', fullName: 'Rhode Island'},
    {shortName: 'SC', fullName: 'South Carolina'},
    {shortName: 'SD', fullName: 'South Dakota'},
    {shortName: 'TN', fullName: 'Tennessee'},
    {shortName: 'TX', fullName: 'Texas'},
    {shortName: 'UT', fullName: 'Utah'},
    {shortName: 'VT', fullName: 'Vermont'},
    {shortName: 'VA', fullName: 'Virginia'},
    {shortName: 'WA', fullName: 'Washington'},
    {shortName: 'WV', fullName: 'West Virginia'},
    {shortName: 'WI', fullName: 'Wisconsin'},
    {shortName: 'WY', fullName: 'Wyoming'}];
  /*URL_REGEX and USERNAME_REGEX used to validate valid urls and username for LinkableMedia*/
  static URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
  // USERNAME_REGEX - accepts numbers, alphabets .(Dot), -(hyphen) and _ (underscore)
  static USERNAME_REGEX = /[^0-9a-zA-Z._-]/g;
  static USERNAME_URL_ERROR = 'Please provide the full url (ie http or https://www.example.com) or @username';
  // Small Business Directory

  static FILTER_CHANGED = 'filterChanged';
  static LIST_HOVERED = 'listHovered';
  static FILTER_COUNT = 'filterCount';
}
