import { Component, ElementRef, Inject, OnInit, Optional, PLATFORM_ID, ViewChild, makeStateKey } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title, TransferState } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserLocationService } from '@app/services/user-location-service/user-location.service';
import { DataService } from '@app/services/data-service/data.service';
import { environment } from '@env/environment';
import { Location, isPlatformBrowser } from '@angular/common';
import { CanonicalService } from '@app/services/canonical/canonical.service';
import { IconMappings } from '@app/IconMapings';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { PageModel } from '@bloomreach/spa-sdk';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private isBrowser: boolean;
    title = 'Staples Connect';
    @ViewChild('topSection', { static: true }) elementView: ElementRef;

    marginTop = 78;
    navFlyOutOpenStatus: boolean;

    constructor(
        private iconReg: MatIconRegistry,
        private sanitizer: DomSanitizer,
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        location: Location,
        private userLocationService: UserLocationService,
        public dataService: DataService,
        private canonicalService: CanonicalService,
        private titleService: Title,
        private transferState: TransferState,
        @Inject(REQUEST) @Optional() private request?: Request | any,
        @Inject('BR_PAGE_MODEL_DATA') @Optional() private brPagemodelData?: PageModel
    ) {
        this.isBrowser = isPlatformBrowser(platformId);

        if (this.isBrowser) {
            this.browserIcons();
            window.onpageshow = function (event) {
                const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

                if (event.persisted && isSafari) {
                    window.location.reload();
                }
            };
        } else {
            const pathname = request.originalUrl;
            if (pathname && !pathname.includes('/connectassets') && !brPagemodelData) {
                const url = pathname.replace('/stores', '');
                const results = pageUrl.filter(value => value.key === url);
                if (results?.length > 0) {
                    this.titleService.setTitle(results[0]?.value);
                }
            }
            this.serverIcons();
        }
    }
    ngOnInit() {
        if (this.isBrowser) {
            this.userLocationService.getCurrentUserLocation();
        }

        if (this.isBrowser) {
            this.router.events.subscribe((path: any) => {
                if (path instanceof NavigationEnd) {
                    this.transferState.remove(makeStateKey('BR_PAGE_MODEL_DATA_KEY'));
                    this.canonicalService.createCanonicalURL();
                }
            });

            // @ts-ignore
            window.Analytics = window.Analytics || {};
            // @ts-ignore
            window.Analytics.traffic = window.Analytics.traffic || {};
            // additional call in SEO component if title changes from default.
            // @ts-ignore
            window.Analytics.traffic.pagename = this.titleService.getTitle();
            // @ts-ignore
            window.Analytics.override = window.Analytics.override || {};
            // @ts-ignore
            window.Analytics.override.traffic = window.Analytics.override.traffic || {};
            // @ts-ignore
            window.Analytics.override.traffic.pagename = this.identifyPageParams();
            window.addEventListener('STAPLES_ANALYTICS_LOADED', function () {
                // @ts-ignore
                window.ae && window.ae.adapter && window.ae.adapter.contentloaded();
            });
        } else {
            this.canonicalService.createCanonicalURL();
        }
    }

    private identifyPageParams(): AnalyticsPageName {
        const routes = window.location.pathname.split('/');
        if (routes[0] === '') {
            routes.shift();
        }
        routes.forEach((route, index) => {
            const refinedRoutes = route.split('-');
            refinedRoutes.forEach((refinedRoute, index) => {
                if (refinedRoute != '') {
                    refinedRoutes[index] =
                        refinedRoute[0].toUpperCase() + refinedRoute.substring(1);
                }
            });
            refinedRoutes.join(' ');
            routes[index] = refinedRoutes.join(' ').split('.')[0];
        });
        let pageTypeSuperCategory = routes[0];
        let classNameEtc = routes[0];
        if (routes.length > 1) {
            pageTypeSuperCategory = routes.slice(0, 2).join(':');
            classNameEtc = routes.join(':');
        }

        return {
            cl: classNameEtc,
            ct: classNameEtc,
            dp: classNameEtc,
            pn: classNameEtc,
            pt: pageTypeSuperCategory,
            sc: pageTypeSuperCategory
        };
    }

    private browserIcons() {
        for (const icon of Object.keys(IconMappings.mapping)) {
            this.iconReg.addSvgIcon(
                icon,
                this.sanitizer.bypassSecurityTrustResourceUrl(
                    environment.siteDNSName + IconMappings.mapping[icon]
                )
            );
        }
    }

    private serverIcons() {
        for (const icon of Object.keys(IconMappings.mapping)) {
            this.iconReg.addSvgIconLiteral(
                icon,
                this.sanitizer.bypassSecurityTrustHtml('<svg></svg>')
            );
        }
    }
}

export interface AnalyticsPageName {
    cl: string; // Class Name
    ct: string; // Category Name
    dp: string; // Department Name
    pn: string; // Page Name
    pt: string; // Page type
    sc: string; // Super category
}

const pageUrl = [
    {
        key: '/services/amazonreturns',
        value: 'Amazon Returns | Staples'
    },
    {
        key: '/services/passport-photos',
        value: 'Passport Photos & Expedited Passport Renewal | Staples'
    },
    {
        key: '/services/amazonpickup',
        value: 'Amazon Hub Lockers | Staples'
    },
    {
        key: '/services/tenbucks',
        value: '$10 OFF When Using Amazon Returns, Happy Returns Or Express Returns | Staples'
    },
    {
        key: '/services/expressreturns',
        value: 'Express Returns | Staples'
    },
    {
        key: '/services/happyreturns',
        value: 'Happy Returns'
    },
    {
        key: '/articles/learning',
        value: 'Classroom Learning | Staples'
    },
    {
        key: '/services/shipping/UPS',
        value: 'UPS® Shipping Services | Staples'
    },
    {
        key: '/weeklyad',
        value: 'Weekly Ad | Staples'
    },
    {
        key: '/ipadtradein',
        value: 'iPad Trade In | Staples'
    },
    {
        key: '/shiptips',
        value: 'Staples Ship Tips | Staples'
    },
    {
        key: '/tech/pcsupport',
        value: 'PC Protection Plans | Staples'
    },
    {
        key: '/p/pctuneup',
        value: 'PC Tune-Up | Staples'
    },
    {
        key: '/tech/protection',
        value: 'Staples Protection Plans & 24/7 Tech Help | Staples'
    },
    {
        key: '/p/inkrewards',
        value: 'Tech Recycling Rewards | Staples'
    },
    {
        key: '/services/directmail',
        value: 'Direct Mail Services | Staples'
    },
    {
        key: '/services/tsa',
        value: 'TSA PreCheck® | Staples'
    },
    {
        key: '/recycling',
        value: 'Recycling Services at Staples | Staples'
    },
    {
        key: '/',
        value: 'The Working & Learning Store | Staples'
    }
];
