import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventService } from '../event/event.service';
import { SEO } from '../../models/SEO';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    seoData = new BehaviorSubject(new SEO());
    olcScriptLoaded = false;

    sideBarToggle = new BehaviorSubject(false);
    observeSideBarToggle = this.sideBarToggle.asObservable();

    expandMenuToggle = new BehaviorSubject(false);
    expandMenuChangeStore = new BehaviorSubject(false);

    isNavLoaded = new BehaviorSubject(false);

    navFlyOutToggle = new BehaviorSubject(false);
    observenavFlyOutToggle = this.navFlyOutToggle.asObservable();

    observeEvents = new BehaviorSubject({});
    observeEventsData = this.observeEvents.asObservable();

    userLocationUpdate = new BehaviorSubject(false);
    observeuserLocationUpdate = this.userLocationUpdate.asObservable();

    sharedFormData = new BehaviorSubject(null);

    drivingTimeSubject = new BehaviorSubject(null);

    loaderSubject = new BehaviorSubject(false);

    // User's lat and lng from Browser or Google
    userLocation = new BehaviorSubject(null);

    selectedStoreTitle = new BehaviorSubject(null);
    selectedStore = new BehaviorSubject(null);
    nearByStores = new BehaviorSubject(null);
    showProdMoreDetails = new BehaviorSubject(false);

    constructor(private eventService: EventService) {}

    toggleSideBar() {
        this.sideBarToggle.next(!this.sideBarToggle.value);
    }

    toggleNavFlyOut() {
        this.navFlyOutToggle.next(!this.navFlyOutToggle.value);
    }

    toggleLoader() {
        this.loaderSubject.next(!this.loaderSubject.value);
    }

    toggleExpandMenu() {
        this.expandMenuToggle.next(!this.expandMenuToggle.value);
    }

    toggleExpandMenuChangeStore() {
        this.expandMenuChangeStore.next(!this.expandMenuChangeStore.value);
    }

    setNavLoadState(state) {
        this.isNavLoaded.next(state);
    }
}
