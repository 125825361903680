import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SmbRegistryService } from '@app/services/smb-registry/smb-registry.service';

@Component({
    selector: 'app-claim-business',
    templateUrl: './claim-business.component.html',
    styleUrls: ['./claim-business.component.scss']
})
export class ClaimBusinessComponent implements OnInit {
    public displayErrorMessage = false;

    constructor(
        private route: ActivatedRoute,
        private smbRegService: SmbRegistryService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.route.queryParams.pipe(take(1)).subscribe(params => {
            if (params.hasOwnProperty('id')) {
                this.callSMB(params.id);
            }
        });
    }

    callSMB(id) {
        this.smbRegService
            .claimBusiness(id)
            .pipe(take(1))
            .subscribe(
                data => {
                    this.router.navigate(['account/mybusinesses']);
                },
                error => {
                    this.displayErrorMessage = true;
                }
            );
    }
}
