<div *ngIf="displayErrorMessage;" >
  <div class="error-wrapper">
    <div class="wrapper">
      <img class="error-page-banner" src="assets/error-page-banner.svg" alt="There was a problem associating the business to your account. Please try again." />
      <h1 class="error-page-title">Oops, that was unexpected</h1>
      <p class="error-message">There was a problem associating the business to your account. Please try again.</p>

      <div>
        <a sc-primary-button [href]="['/']">
          Visit staplesconnect.com
        </a>
      </div>

    </div>
  </div>
</div>
