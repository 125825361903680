import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {RouterModule} from '@angular/router';
import { DynamicCtaComponent } from './dynamic-cta.component';
import { ButtonModule } from '@staples-connect/component-library';
import { GaTrackerDirective } from '@app/ga-tracker.directive';


@NgModule({
    declarations: [DynamicCtaComponent],
    imports: [
      ButtonModule,
        CommonModule,
        NgbCarouselModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        RouterModule,
        GaTrackerDirective
    ],
    exports: [DynamicCtaComponent]
})
export class DynamicCtaModule { }
