import { ChangeDetectorRef, Component, Inject, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import {DataService} from '../../../services/data-service/data.service';
import {SharedUtilsService} from '../../../utils/shared-utils/shared-utils.service';
import {SmbRegistryService} from '../../../services/smb-registry/smb-registry.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {Subscription} from 'rxjs';
import { CommonModule, KeyValue } from '@angular/common';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import {take} from 'rxjs/operators';
import { MaterialComponentsModule } from '@app/material-components.module';
import { BusinessesByCityComponent } from '@app/cms-components/small-business-registry/businesses-by-city/businesses-by-city.component';
import { NoBusinessNearYouComponent } from '@app/cms-components/small-business-registry/no-business-near-you/no-business-near-you.component';

@Component({
  selector: 'rde-smb-directory-near-me-page',
  templateUrl: './smb-directory-near-me-page.component.html',
  styleUrls: ['./smb-directory-near-me-page.component.scss']
})
export class SmbDirectoryNearMePageComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  componentModels: any;
  page: Page;
  content: any;
  @Input() configuration: any;
  businesses: any;

  public currentPage = 1;
  private eventsPerPage = 2;
  private indexOfLastEvent: number;
  public indexOfFirstEvent: number;
  private eventsOnPage = [];
  public totalPages = 0;
  public showLoader = false;
  public showNoBusinesses = false;
  public statuses: any[];
  public categories: any[];
  public selectedCategory: any;
  public selectedRadius: any;
  public selectedStatus: any;
  private locationSub: Subscription;
  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  constructor(
    private sharedUtilsService: SharedUtilsService,
    private smbRegistryService: SmbRegistryService,
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    @Inject('component') component: any | BrComponent,
    @Inject('page') page: any | Page
  ) {
    this.component = component;
    this.page = page;
  }

  ngOnInit() {
    this.componentModels = this.component.getModels();
    this.statuses = this.componentModels.statuses;
    this.categories = this.componentModels.category;
    this.selectedCategory = this.activatedRoute.snapshot.queryParams.category || 'All';
    this.selectedStatus = this.activatedRoute.snapshot.queryParams.status || 'All';
    this.selectedRadius = this.activatedRoute.snapshot.queryParams.radius || '25';
    this.showLoader = true;
    this.locationSub = this.dataService.selectedStore.subscribe( locationChange => {
      if (locationChange) {

        this.loadBusinesses();
      }
    });
  }

  loadBusinesses() {
    // if (!this.dataService.selectedStore.getValue()) {
    //   return;
    // }
    const category = this.activatedRoute.snapshot.queryParams.category || '';
    const status = this.activatedRoute.snapshot.queryParams.status || '';
    const radius = this.activatedRoute.snapshot.queryParams.radius || '';

    this.smbRegistryService.getBusinessesNearMe(category, status, radius).pipe(take(1)).subscribe(result => {
      this.businesses = result;
      this.showLoader = false;
      this.showNoBusinesses = false;
      this.ref.markForCheck();
    }, () => {
      this.showLoader = false;
      this.showNoBusinesses = true;
      this.ref.detectChanges();
    });
  }

  loadPaginatorData() {
    this.sharedUtilsService.scrollTo();
    this.indexOfLastEvent = this.currentPage * this.eventsPerPage;
    this.eventsOnPage = [];
    this.indexOfFirstEvent = this.indexOfLastEvent - this.eventsPerPage;


  }

  onPrevButtonClick() {
    this.currentPage = this.currentPage - 1;
    this.loadPaginatorData();
  }

  onNextButtonClick() {
    this.currentPage = this.currentPage + 1;
    this.loadPaginatorData();
  }

  onFilterChange() {
    // this.selectedCity = 'Filter by City';
    const queryParameter: any = {};
    if (this.selectedCategory && this.selectedCategory !== 'All') {
      queryParameter.category = this.selectedCategory;
      // queryParameter = {category: this.selectedCategory};
    }

    if (this.selectedRadius && this.selectedRadius !== 'All') {
      queryParameter.radius = this.selectedRadius;
      // queryParameter = {radius: this.selectedRadius};
    }

    if (this.selectedStatus && this.selectedStatus !== 'All') {
      queryParameter.status = this.selectedStatus;
      // queryParameter = {radius: this.selectedStatus};
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParameter,
      replaceUrl: true
    });
  }

  ngOnDestroy(): void {
    if (this.locationSub) {
      this.locationSub.unsubscribe();
    }
  }
}

@NgModule({
 declarations: [
   SmbDirectoryNearMePageComponent,
   BusinessesByCityComponent,
   NoBusinessNearYouComponent
 ],
  exports: [
    BusinessesByCityComponent,
    NoBusinessNearYouComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    RouterModule
  ]
})
export class SmbDirectoryNearMePageModule{}
