import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckerboardPromoBlockComponent } from '@app/cms-components/checkerboard-promo/checkerboard-promo-block/checkerboard-promo-block.component';
import { RouterModule } from '@angular/router';
import { ButtonModule, ScAnimationsModule } from '@staples-connect/component-library';
import { CheckerboardPromoTileTextComponent } from '@app/cms-components/checkerboard-promo/checkboard-promo-tile-text/checkerboard-promo-tile-text.component';
import { CheckerboardPromoTilePhotoComponent } from '@app/cms-components/checkerboard-promo/checkerboard-promo-tile-photo/checkerboard-promo-tile-photo.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselSliderModule } from '@app/rde-components/carousel-slider/carousel-slider.module';
import { SlidePanelComponent } from './slide-panel/slide-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { DynamicCtaModule } from '@app/rde-components/dynamic-cta/dynamic-cta.module';
import { DynamicHTagModule } from '../dynamic-h-tag/dynamic-h-tag.module';
import { UtilsModule } from '@app/utils/utils.module';
@NgModule({
    declarations: [
        CheckerboardPromoBlockComponent,
        CheckerboardPromoTileTextComponent,
        CheckerboardPromoTilePhotoComponent,
        SlidePanelComponent
    ],

    exports: [
        CheckerboardPromoBlockComponent,
        CheckerboardPromoTileTextComponent,
        CheckerboardPromoTilePhotoComponent,
        SlidePanelComponent
    ],

    imports: [
        CommonModule,
        RouterModule,
        ButtonModule,
        RouterModule,
        ScAnimationsModule,
        BrowserAnimationsModule,
        CarouselSliderModule,
        MatIconModule,
        DynamicCtaModule,
        DynamicHTagModule,
        UtilsModule
    ]
})
export class CheckerboardPromoModule {}
