import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {ButtonModule} from '@staples-connect/component-library';
import { MaterialComponentsModule } from '../../material-components.module';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { RdeComponentsModule } from '@app/rde-components/rde-components.module';
import {UtilsModule} from '@app/utils/utils.module';
import {  NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';



@NgModule({
  declarations: [
    ErrorDialogComponent],
  exports: [],
    imports: [
        CommonModule,
        ButtonModule,
        MaterialComponentsModule,
        ReactiveFormsModule,
        RouterModule,
         NgxMaskDirective, NgxMaskPipe,
        RdeComponentsModule,
        UtilsModule
    ],
    providers: [provideNgxMask()]
})
export class ShowroomModule { }
