import { Component, Injector, Input, OnInit, Type } from '@angular/core';
import { Component as BrComponent, Document, Page, Reference } from '@bloomreach/spa-sdk';
import { BookAppointmentIframeComponent } from '@app/cms-components/book-appointment-iframe/book-appointment-iframe.component';
import { ClearLocatorModule } from '@app/cms-components/clear-locator/clear-locator.component';
interface BannerModels {
    document: Reference;
}

@Component({
    selector: 'rde-dynamic-loader',
    templateUrl: './dynamic-loader.component.html'
})
export class DynamicLoaderComponent implements OnInit {
    @Input() component!: BrComponent;
    @Input() page!: Page;
    dynamicComponent: Promise<Type<any>> | null | undefined;
    dInjector: Injector | undefined;
    constructor(private injector: Injector) {}

    ngOnInit(): void {
        const cmp: any = this.component;

        this.dInjector = Injector.create({
            providers: [
                {
                    provide: 'component',
                    useValue: this.component
                },
                {
                    provide: 'page',
                    useValue: this.page
                }
            ],
            parent: this.injector
        });
        this.dynamicComponent = this.getImport(cmp.model.label);
    }

    getImport(label: string): any {
        switch (label) {
            case 'Brand Launch Hero Component':
                return import(
                    `@app/cms-components/brand-launch-hero/brand-launch-hero.component`
                ).then(({ BrandLaunchHeroComponent }) => BrandLaunchHeroComponent);
            case 'Spacer Component':
                return import(`@app/cms-components/spacer/spacer.component`).then(
                    ({ SpacerComponent }) => SpacerComponent
                );
            case 'Single Video Module':
                return import(
                    `@app/cms-components/single-video-module/single-video-module.component`
                ).then(({ SingleVideoModuleComponent }) => SingleVideoModuleComponent);
            case 'Content Carousel':
                return import(
                    `@app/cms-components/content-carousel/content-carousel.component`
                ).then(({ ContentCarouselComponent }) => ContentCarouselComponent);
            case 'Center Single Video Component':
                return import(
                    `@app/cms-components/single-video-module/single-video-module.component`
                ).then(({ SingleVideoModuleComponent }) => SingleVideoModuleComponent);
            case 'ThreeColumnContentComponent':
                return import(
                    `@app/cms-components/three-column-content-component/three-column-content/three-column-content.component`
                ).then(({ ThreeColumnContentComponent }) => ThreeColumnContentComponent);
            case 'AlertBanner':
                return import(`@app/cms-components/alert-banner/alert-banner.component`).then(
                    ({ AlertBannerComponent }) => AlertBannerComponent
                );

            case 'InStore Services':
                return import(
                    `@app/cms-components/in-store-services/in-store-services.component`
                ).then(({ InStoreServicesComponent }) => InStoreServicesComponent);

            case 'Editors Pick':
                return import(
                    `@app/cms-components/ideas-landing-page-component/editors-pick/editors-pick.component`
                ).then(({ EditorsPickComponent }) => EditorsPickComponent);

            case 'Loyalty Dashboard Component':
                return import(
                    `@app/cms-components/loyalty/loyalty-dashboard/loyalty-dashboard.component`
                ).then(({ LoyaltyDashboardComponent }) => LoyaltyDashboardComponent);

            case 'Footer':
                return import(`@app/cms-components/cms-footer/cms-footer.component`).then(
                    ({ CmsFooterComponent }) => CmsFooterComponent
                );

            case 'ArticlesV2':
                return import(`@app/cms-components/articles-v2/articles-v2.component`).then(
                    ({ ArticlesV2Component }) => ArticlesV2Component
                );

            case 'SMB Registry Form V2 Component':
                return import(
                    `@app/cms-components/smb-registry-form-v2/smb-registry-form-v2.component`
                ).then(({ SmbRegistryFormV2Component }) => SmbRegistryFormV2Component);

            case 'SMB Registry Form Component':
                return import(
                    `@app/cms-components/smb-registry-form/smb-registry-form.component`
                ).then(({ SmbRegistryFormComponent }) => SmbRegistryFormComponent);

            case 'Image Carousel':
                return import(
                    `@app/cms-components/product-image-carousel/product-image-carousel.component`
                ).then(({ ProductImageCarouselComponent }) => ProductImageCarouselComponent);

            case 'Marketing Information List':
                return import(
                    `@app/cms-components/marketing-information-list/marketing-information-list.component`
                ).then(
                    ({ MarketingInformationListComponent }) => MarketingInformationListComponent
                );

            case 'Trending Products':
                return import(
                    `@app/cms-components/expand-menu/trending-products/trending-products.component`
                ).then(({ TrendingProductsComponent }) => TrendingProductsComponent);

            case 'MenuExploreOurCommunityComponent':
                return import(
                    `@app/cms-components/expand-menu/em-explore-community/em-explore-community.component`
                ).then(({ EmExploreCommunityComponent }) => EmExploreCommunityComponent);
            case 'MenuStoreInformationComponent':
                return import(
                    `@app/cms-components/expand-menu/em-store-information/em-store-information.component`
                ).then(({ EmStoreInformationComponent }) => EmStoreInformationComponent);

            case 'MenuGMMessageComponent':
                return import(
                    `@app/cms-components/expand-menu/em-gm-message/em-gm-message.component`
                ).then(({ EMGMMessageComponent }) => EMGMMessageComponent);

            case 'MenuGMPMMessageComponent':
                return import(
                    `@app/cms-components/expand-menu/em-gm-pm-message/em-gm-pm-message.component`
                ).then(({ EmGmPmMessageComponent }) => EmGmPmMessageComponent);

            case 'Oct2021PossibleLPComponentAngular':
                return import(`@app/cms-components/oct-possible-page-future-of-work`).then(
                    ({ OctPossiblePageFutureOfWorkComponent }) =>
                        OctPossiblePageFutureOfWorkComponent
                );

            case 'BDS Homepage':
                return import(`@app/cms-components/bd-hompage/bd-hompage.component`).then(
                    ({ BdHompageComponent }) => BdHompageComponent
                );

            case 'Showroom Product Info Component':
                return import(
                    `@app/cms-components/showroom/showroom-product-info/showroom-product-info.component`
                ).then(({ ShowroomProductInfoComponent }) => ShowroomProductInfoComponent);

            case 'Showroom Buy Button Component':
                return import(
                    `@app/cms-components/showroom/showroom-buy-button/showroom-buy-button.component`
                ).then(({ ShowroomBuyButtonComponent }) => ShowroomBuyButtonComponent);

            case 'Showroom Cart Component':
                return import(
                    `@app/cms-components/showroom/showroom-cart/showroom-cart.component`
                ).then(({ ShowroomCartComponent }) => ShowroomCartComponent);

            case 'Showroom Checkout Component':
                return import(
                    `@app/cms-components/showroom/showroom-checkout/showroom-checkout.component`
                ).then(({ ShowroomCheckoutComponent }) => ShowroomCheckoutComponent);

            case 'Showroom Order Confirmation Component':
                return import(
                    `@app/cms-components/showroom/showroom-order-confirmation/showroom-order-confirmation.component`
                ).then(
                    ({ ShowroomOrderConfirmationComponent }) => ShowroomOrderConfirmationComponent
                );

            case 'Showroom Order Confirmation Next Steps Component':
                return import(
                    `@app/cms-components/showroom/showroom-order-confirmation-next-steps/showroom-order-confirmation-next-steps.component`
                ).then(
                    ({ ShowroomOrderConfirmationNextStepsComponent }) =>
                        ShowroomOrderConfirmationNextStepsComponent
                );

            case 'Thin Informational banner':
                return import(
                    `@app/cms-components/thin-informational-banner/thin-informational-banner.component`
                ).then(({ ThinInformationalBannerComponent }) => ThinInformationalBannerComponent);

            case 'Hotspot Carousel Component':
                return import(`@app/cms-components/hotspot-carousel`).then(
                    ({ HotspotCarouselComponent }) => HotspotCarouselComponent
                );
            case 'Article Pillars Grid':
                return import(`@app/cms-components/articles-v2-landing-page`).then(
                    ({ ArticlesPillarGridComponent }) => ArticlesPillarGridComponent
                );

            case 'Blog List':
                return import(`@app/cms-components/ideas-landing-page-component`).then(
                    ({ ArticleGridComponent }) => ArticleGridComponent
                );

            case 'Ideas Banner':
                return import(`@app/cms-components/ideas-landing-page-component`).then(
                    ({ IdeasLandingPageHeaderComponent }) => IdeasLandingPageHeaderComponent
                );
            case 'Blog Detail':
            case 'Article':
                return import(`@app/cms-components/ideas-article-page-component`).then(
                    ({ IdeasArticlePageComponent }) => IdeasArticlePageComponent
                );
            case 'Smb Registry Business Details Page V2 Component':
                return import(
                    `@app/cms-components/small-business-directory/small-business-details-page/smb-registry-business-details-page-v2/smb-registry-business-details-page-v2.component`
                ).then(
                    ({ SmbRegistryBusinessDetailsPageV2Component }) =>
                        SmbRegistryBusinessDetailsPageV2Component
                );

            case 'Small Business Directory Component':
                return import(
                    `@app/cms-components/small-business-directory/small-business-directory.component`
                ).then(({ SmallBusinessDirectoryComponent }) => SmallBusinessDirectoryComponent);

            case 'SMB Registry Businesses Near You Component':
                return import(
                    `@app/cms-components/small-business-registry/smb-directory-near-me-page/smb-directory-near-me-page.component`
                ).then(({ SmbDirectoryNearMePageComponent }) => SmbDirectoryNearMePageComponent);

            case 'Smb Registry Business Details Page Component':
                return import(
                    `@app/cms-components/small-business-registry/smb-registry-business-details-page/smb-registry-business-details-page.component`
                ).then(
                    ({ SmbRegistryBusinessDetailsPageComponent }) =>
                        SmbRegistryBusinessDetailsPageComponent
                );

            case 'SEO':
                return import(`@app/cms-components/seo/seo.component`).then(
                    ({ SeoComponent }) => SeoComponent
                );

            case 'ErrorMessage':
                return import(`@app/cms-components/page-not-found`).then(
                    ({ PageNotFoundComponent }) => PageNotFoundComponent
                );

            case 'Legal Page':
                return import(`@app/cms-components/sc-terms-and-conditions`).then(
                    ({ ScTermsAndConditionsComponent }) => ScTermsAndConditionsComponent
                );

            case 'Fullscreen Informational Video Banner':
                return import(`@app/cms-components/fullscreen-informational-video-banner`).then(
                    ({ FullscreenInformationalVideoBannerComponent }) =>
                        FullscreenInformationalVideoBannerComponent
                );

            case 'Marketing Banner':
                return import(`@app/cms-components/marketing-banner`).then(
                    ({ MarketingBannerComponent }) => MarketingBannerComponent
                );

            case 'Large Informational Block':
                return import(`@app/cms-components/large-informational-block`).then(
                    ({ LargeInformationalBlockComponent }) => LargeInformationalBlockComponent
                );

            case 'Generic Banner':
                return import(`@app/cms-components/generic-banner`).then(
                    ({ GenericBannerComponent }) => GenericBannerComponent
                );

            case 'Small Card Grid':
                return import(`@app/cms-components/small-card-grid`).then(
                    ({ SmallCardGridComponent }) => SmallCardGridComponent
                );

            case 'FAQ':
                return import(`@app/cms-components/faq`).then(({ FaqComponent }) => FaqComponent);

            case 'Service Banner':
                return import(`@app/cms-components/service-banner/service-banner.component`).then(
                    ({ ServiceBannerComponent }) => ServiceBannerComponent
                );

            case 'Service Offering List':
                return import('@app/cms-components/service-offering-list-wrapper').then(
                    ({ ServiceOfferingListComponent }) => ServiceOfferingListComponent
                );

            case 'Service Highlight List':
                return import(
                    `@app/cms-components/service-highlight-list/service-highlight-list.component`
                ).then(({ ServiceHighlightListComponent }) => ServiceHighlightListComponent);

            case 'Related Articles':
                return import(`@app/cms-components/ideas-article-page-component`).then(
                    ({ RelelatedArticlesComponent }) => RelelatedArticlesComponent
                );

            case 'Product Brief':
                return import(`@app/cms-components/product-brief/product-brief.component`).then(
                    ({ ProductBriefComponent }) => ProductBriefComponent
                );

            case 'Product Badging':
                return import(`@app/cms-components/product-badging/product-badging.component`).then(
                    ({ ProductBadgingComponent }) => ProductBadgingComponent
                );

            case 'Curbside Pickup Order Detail Component':
                return import(`@app/cms-components/curbside-pickup`).then(
                    ({ CurbsidePickupWrapperComponent }) => CurbsidePickupWrapperComponent
                );

            case 'Product Card Carousel':
                return import(`@app/cms-components/product-card-component`).then(
                    ({ ProductCardCarouselComponent }) => ProductCardCarouselComponent
                );

            case 'Articles By Category Component':
                return import(
                    `@app/cms-components/ideas-landing-page-component/articles-by-category`
                ).then(({ ArticlesByCategoryComponent }) => ArticlesByCategoryComponent);
            case 'RDE Carousel Component':
                return import(`@app/cms-components/rdecarousel`).then(
                    ({ RDECarouselComponent }) => RDECarouselComponent
                );
            case 'Video Banner':
                return import(`@app/cms-components/video-banner`).then(
                    ({ VideoBannerComponent }) => VideoBannerComponent
                );
            case 'Left Image Text Banner':
                return import(`@app/cms-components/left-image-text-banner`).then(
                    ({ LeftImageTextBannerComponent }) => LeftImageTextBannerComponent
                );
            case '3 Featured Articles Component':
                return import(`@app/cms-components/three-featured-articles`).then(
                    ({ ThreeFeaturedArticlesComponent }) => ThreeFeaturedArticlesComponent
                );
            case 'Blog Video Detail':
                return import(
                    `@app/cms-components/ideas-article-page-component/video-blog-post-wrapper`
                ).then(({ VideoBlogPostWrapperComponent }) => VideoBlogPostWrapperComponent);

            case 'Text Banner With Cta':
                return import(`@app/cms-components/text-banner-with-cta`).then(
                    ({ TextBannerWithCtaComponent }) => TextBannerWithCtaComponent
                );

            case 'Bopis Pickup Order Detail Component':
                return import(`@app/cms-components/bopis-pickup/bopis-pickup-order-detail`).then(
                    ({ BopisPickupOrderDetailComponent }) => BopisPickupOrderDetailComponent
                );

            case 'Product Vignette With Banner':
                return import(`@app/cms-components/product-vignette-with-banner`).then(
                    ({ ProductVignetteWithBannerComponent }) => ProductVignetteWithBannerComponent
                );

            case 'PillarBannerWithBox':
                return import(`@app/cms-components/pillar-banner-with-box`).then(
                    ({ PillarBannerWithBoxComponent }) => PillarBannerWithBoxComponent
                );

            case 'OfferBlockTwoOffers':
                return import(`@app/cms-components/offer-block-two-offers`).then(
                    ({ OfferBlockTwoOffersComponent }) => OfferBlockTwoOffersComponent
                );

            case 'Pro Services List':
                return import(`@app/cms-components/pro-services-list`).then(
                    ({ ProServicesListComponent }) => ProServicesListComponent
                );

            case 'Free Form Text Component':
                return import(`@app/cms-components/free-form-text`).then(
                    ({ FreeFormTextComponent }) => FreeFormTextComponent
                );

            case 'Free Form Text Carousel':
                return import(`@app/cms-components/free-form-text-carousel`).then(
                    ({ FreeFormTextCarouselComponent }) => FreeFormTextCarouselComponent
                );

            case 'Community Banner':
                return import(`@app/cms-components/community-banner`).then(
                    ({ CommunityBannerComponent }) => CommunityBannerComponent
                );

            case 'Checkerboard Component':
                return import(`@app/cms-components/checkerboard-promo`).then(
                    ({ CheckerboardPromoBlockComponent }) => CheckerboardPromoBlockComponent
                );

            case 'EmbedCode Component':
                return import(`@app/cms-components/embed-code`).then(
                    ({ EmbedCodeComponent }) => EmbedCodeComponent
                );

            case 'IFrame Component':
                return import(`@app/cms-components/iframe`).then(
                    ({ IFrameComponent }) => IFrameComponent
                );

            case 'Promo Block':
                return import(`@app/cms-components/promo-block/promo-block`).then(
                    ({ PromoBlockComponent }) => PromoBlockComponent
                );

            case 'Single Coupon Component':
                return import(`@app/cms-components/coupon`).then(
                    ({ CouponComponent }) => CouponComponent
                );

            case 'Online Circular Component':
                return import(`@app/cms-components/online-circular`).then(
                    ({ OnlineCircularComponent }) => OnlineCircularComponent
                );

            case 'Two Column Timer Carousel':
                return import(
                    `@app/cms-components/timer-carousel-animation/timer-carousel-animation.component`
                ).then(({ TimerCarouselAnimationComponent }) => TimerCarouselAnimationComponent);

            case 'MultipleVideoModule':
                return import(`@app/cms-components/multiple-video-module`).then(
                    ({ MultipleVideoModuleComponent }) => MultipleVideoModuleComponent
                );
            case 'Social Share Component':
                return import('@app/cms-components/social-share/social-share.component').then(
                    ({ SocialShareComponent }) => SocialShareComponent
                );
            case 'Help Center Parent List Component':
                return import('@app/cms-components/help-center/help-center.component').then(
                    ({ HelpCenterComponent }) => HelpCenterComponent
                );
            case 'Faq Component':
                return import('@app/cms-components/faq/faq.component').then(
                    ({ FaqComponent }) => FaqComponent
                );
            case 'Screaming Offer Banner':
                return import(
                    '@app/cms-components/screaming-offer-banner/screaming-offer-banner.component'
                ).then(({ ScreamingOfferBannerComponent }) => ScreamingOfferBannerComponent);
            case 'Help Center FAQ Component':
                return import('@app/cms-components/help-center-faq/help-center-faq.component').then(
                    ({ HelpCenterFAQComponent }) => HelpCenterFAQComponent
                );

            case 'My Business Dashboard Component':
                return import('@app/cms-components/self-serve/self-serve.component').then(
                    ({ SelfServeComponent }) => SelfServeComponent
                );

            case 'Help Center SideNav Component':
                return import(
                    '@app/cms-components/help-center-side-nav/help-center-side-nav.component'
                ).then(({ HelpCenterSideNavComponent }) => HelpCenterSideNavComponent);

            case 'Breadcrumb':
                return import(
                    '@app/cms-components/help-center/breadcrumb/breadcrumb.component'
                ).then(({ BreadcrumbComponent }) => BreadcrumbComponent);
            case 'Contact Form':
                return import('@app/cms-components/contact-form/contact-form.component').then(
                    ({ ContactFormComponent }) => ContactFormComponent
                );
            case 'Floating Action Button':
                return import('@app/cms-components/fab-button/fab-button.component').then(
                    ({ FabButtonComponent }) => FabButtonComponent
                );
            case 'AnchorTag':
                return import('@app/cms-components/anchor-tag/anchor-tag.component').then(
                    ({ AnchorTagComponent }) => AnchorTagComponent
                );
            case 'Full Bleed Video':
                return import('@app/cms-components/fullbleed-video/fullbleed-video.component').then(
                    ({ FullbleedVideoComponent }) => FullbleedVideoComponent
                );
            case 'TSA Service':
                return import('@app/cms-components/location-component/location.component').then(
                    ({ LocationComponent }) => LocationComponent
                );
            case 'Location Component':
                return import('@app/cms-components/location-component/location.component').then(
                    ({ LocationComponent }) => LocationComponent
                );
            case 'StandaloneCTAComponent':
                return import('@app/cms-components/cta/cta.component').then(
                    ({ CtaComponent }) => CtaComponent
                );
            case 'GenericIframe Embed Component':
                return import(
                    '@app/cms-components/iframe/generic-iframe-component/generic-iframe-component.component'
                ).then(({ GenericIframeComponent }) => GenericIframeComponent);
            case 'Oct2021PossibleLPComponent':
                return import(
                    '@app/cms-components/oct-2021-possible-lp/oct-2021-possible-lp.component'
                ).then(({ Oct2021PossibleLpComponent }) => Oct2021PossibleLpComponent);
            case 'Print Marketing Service':
                return import(
                    '@app/cms-components/print-marketing-service/print-marketing-service.component'
                ).then(({ PrintMarketingServiceComponent }) => PrintMarketingServiceComponent);
            case 'Article Search Banner':
                return import(
                    '@app/cms-components/articles-v2/articles-search-bar/articles-search-bar.component'
                ).then(({ ArticlesSearchBarComponent }) => ArticlesSearchBarComponent);
            case 'Product Selection Component':
                return import('@app/cms-components/product-selection').then(
                    ({ ProductSelectionComponent }) => ProductSelectionComponent
                );
            case 'Count Down Component':
                return import('@app/cms-components/count-down/count-down.component').then(
                    ({ CountDownComponent }) => CountDownComponent
                );
            case 'Articles By Pillar Component':
                return import('@app/cms-components/articles-v2-landing-page').then(
                    ({ ArticlesPillarComponent }) => ArticlesPillarComponent
                );
            case 'Article Search List':
                return import('@app/cms-components/articles-search/articles-search.component').then(
                    ({ ArticlesSearchComponent }) => ArticlesSearchComponent
                );
            case 'Scheduler Check In Component':
                return import('@app/cms-components/scheduler/scheduler.component').then(
                    ({ SchedulerComponent }) => SchedulerComponent
                );
            case 'Scheduler Status Component':
                return import(
                    '@app/cms-components/scheduler/scheduler-status/scheduler-status.component'
                ).then(({ SchedulerStatusComponent }) => SchedulerStatusComponent);
            case 'Scheduler Waitlist Component':
                return import(
                    '@app/cms-components/scheduler/scheduler-waitlist/scheduler-waitlist.component'
                ).then(({ SchedulerWaitlistComponent }) => SchedulerWaitlistComponent);
            case 'Large Center Image Block Component':
                return import(
                    '@app/cms-components/large-center-image-block/large-center-image-block.component'
                ).then(({ LargeCenterImageBlockComponent }) => LargeCenterImageBlockComponent);
            case 'Carousel With Slider':
                return import(
                    '@app/cms-components/carousel-with-slider/carousel-with-slider.component'
                ).then(({ CarouselWithSliderComponent }) => CarouselWithSliderComponent);
            case 'Image Parallex Component':
                return import('@app/cms-components/image-parallax/image-parallax.component').then(
                    ({ ImageParallaxComponent }) => ImageParallaxComponent
                );

            case 'Trending Product Carousel Component':
                return import(
                    '@app/cms-components/small-business-directory/small-business-details-page/smb-registry-business-details-page-v2/smb-registry-trending-products/'
                ).then(
                    ({ SmbRegistryTrendingProductsComponent }) =>
                        SmbRegistryTrendingProductsComponent
                );

            case 'Recycle Component':
                return import('@app/cms-components/recycle/recycle.component').then(
                    ({ RecycleComponent }) => RecycleComponent
                );
            case 'Store Specialist':
                return import(
                    '@app/cms-components/store-specialist/store-specialist.component'
                ).then(({ StoreSpecialistComponent }) => StoreSpecialistComponent);

            case 'Local Store Component':
                return import('@app/cms-components/local-store/local-store.component').then(
                    ({ LocalStoreComponent }) => LocalStoreComponent
                );

            case 'Store Services Component':
                return import('@app/cms-components/store-services/store-services.component').then(
                    ({ StoreServicesComponent }) => StoreServicesComponent
                );

            case 'User Location Debugger':
                return import(
                    '@app/services/user-location-service/user-location-debug.component'
                ).then(({ UserLocationDebugComponent }) => UserLocationDebugComponent);
            case 'Yeti Intake Form':
                return import('@app/cms-components/yeti-form/yeti-form.component').then(
                    ({ YetiFormComponent }) => YetiFormComponent
                );

            case 'Grid Guide Debugger':
                return import('@app/utils/grid-guide/grid-guide.component').then(
                    ({ GridGuideComponent }) => GridGuideComponent
                );

            case 'Email Breach Search Component':
                return import('@app/cms-components/email-breach-search').then(
                    ({ EmailBreachSearchComponent }) => EmailBreachSearchComponent
                );

            case 'Single Use Coupon Component':
                return import('@app/cms-components/single-use-coupon').then(
                    ({ SingleUseCouponComponent }) => SingleUseCouponComponent
                );

            case 'Weekly Ad Component':
                return import('@app/cms-components/weekly-ad/weekly-ad.component').then(
                    ({ WeeklyAdComponent }) => WeeklyAdComponent
                );
            case 'Weekly Ad Promo Block Component':
                return import(
                    '@app/cms-components/weekly-ad/weekly-ad-promo-block/weekly-ad-promo-block.component'
                ).then(({ WeeklyAdPromoBlockComponent }) => WeeklyAdPromoBlockComponent);
            case 'Promo Banner':
                return import('@app/cms-components/promo-banner/promo-banner.component').then(
                    ({ PromoBannerComponent }) => PromoBannerComponent
                );
            case 'Clear Store Locator':
                return import('@app/cms-components/clear-locator/clear-locator.component').then(
                    ({ ClearLocatorComponent }) => ClearLocatorComponent
                );
            case 'BookAppointmentIFrame':
                return import(
                    '@app/cms-components/book-appointment-iframe/book-appointment-iframe.component'
                ).then(({ BookAppointmentIframeComponent }) => BookAppointmentIframeComponent);
            case 'Printer Finder':
                return import('@app/cms-components/printer-finder/printer-finder.component').then(
                    ({ PrinterFinderComponent }) => PrinterFinderComponent
                );
            case 'Services Breadcrumb':
                return import(
                    '@app/cms-components/help-center/services-breadcrumb/services-breadcrumb.component'
                ).then(({ ServicesBreadcrumbComponent }) => ServicesBreadcrumbComponent);
            case 'Inline Coupon Component':
                return import('@app/cms-components/inline-coupon/inline-coupon.component').then(
                    ({ InlineCouponComponent }) => InlineCouponComponent
                );
            default:
                return null;
        }
    }

    get document(): any {
        const { document } = this.component.getModels<BannerModels>();

        return document && this.page.getContent<Document>(document);
    }
}
