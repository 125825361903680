<div class="event-detail-body-wrapper">
  <div class="event-description-wrapper">
    <div class="event-details">
      <div *ngIf="business.publish.message.about" class="community-message-wrapper">
        <h5>Message to the local community</h5>
        <p> {{business.publish.message.about}}</p>
      </div>
      <div *ngIf="business.publish.message.appealToCommunity" class="community-message-wrapper">
        <h5>How is your business doing?</h5>
        <p> {{business.publish.message.appealToCommunity}}</p>
      </div>
      <div *ngIf="business.publish.message.tipsForOtherSmallBusinesses" class="community-message-wrapper">
        <h5>Tips to other small local businesses?</h5>
        <p> {{business.publish.message.tipsForOtherSmallBusinesses}}</p>
      </div>
      <div *ngIf="business.publish.message.requestedMethodsOfSupport?.length > 0" class="community-message-wrapper">
        <h5>If you're a local business and can support us with the below tasks, please reach out</h5>
        <ul>
          <li *ngFor="let task of addSupportArray">{{task}}</li>
        </ul>
      </div>
      <div class="social-no-body" *ngIf="noBody">
        <h5>Help share our profile</h5>
        <rde-social-media-button-list [horizontal]="true" [isArticle]="false" style="float: left"></rde-social-media-button-list>
      </div>
    </div>
  </div>
  <div class="share-wrapper" *ngIf="!noBody">
    <h5>Help share our profile</h5>
    <rde-social-media-button-list class="social-vert" [horizontal]="false" [isArticle]="false"></rde-social-media-button-list>
    <rde-social-media-button-list class="social-hor" [horizontal]="true" [isArticle]="false"></rde-social-media-button-list>
  </div>
  <!--  <rde-event-venue-timings-wrapper class="event-venue-timings-wrapper" [eventDetails]="eventDetails"></rde-event-venue-timings-wrapper>-->
</div>
<div class="google-map" *ngIf="!business.publish.businessInformation.makeAddressPrivate">
  <rde-google-map [latitude]="business.publish.businessInformation.address.location.y"
                  [longitude]="business.publish.businessInformation.address.location.x" [showinfoWindow]="true"
                  [useStaplesMarker]="false" [zoomControl]="true" [styles]="mapStyle"></rde-google-map>
</div>
<div class="body-footer-wrapper ">
  <hr>
  <div class="body-footer-text">
    <h5>Is this your business?</h5>
    <p>We are working on an update that will allow you to edit your listing, but if you have any urgent changes in the
      meantime, please reply to your original sign up email. For any other requests email
      <a class="font-staples-red" href="mailto:SmallBusinessDirectory@Staples.com">SmallBusinessDirectory@Staples.com</a></p>
  </div>
</div>
