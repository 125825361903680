import {Component, Input, OnInit} from '@angular/core';
import { List } from '@app/rde-components/list/list.model';
import { StoreService } from '@app/services/store/store.service';

@Component({
  selector: 'rde-smb-business-page-header',
  templateUrl: './smb-business-page-header.component.html',
  styleUrls: ['./smb-business-page-header.component.scss']
})
export class SmbBusinessPageHeaderComponent implements OnInit {

  public googleLink: string;
  public appleLink: string;
  public addressLink: string;
  @Input() business: any;
  public mediaLinks: any;
  public serviceOptionsString: string;
  serviceOptionsList: List[] = [];

  constructor(private storeService: StoreService) { }

  ngOnInit() {
    this.mediaLinks = this.business.publish.businessInformation.linkableMedias;
    const googleMap = (element) => element.site.toUpperCase() === 'GOOGLEMAPS';
    const googleMapsIndex = this.mediaLinks.findIndex(googleMap);
    this.googleLink = googleMapsIndex > -1 ? this.mediaLinks[googleMapsIndex].siteUrl :
      // eslint-disable-next-line max-len
      `https://www.google.com/maps/search/?api=1&query=${this.business.publish.businessInformation.name}+${this.business.publish.businessInformation.address.address1}+${this.business.publish.businessInformation.address.city}+${this.business.publish.businessInformation.address.state}+${this.business.publish.businessInformation.address.postalCode}`;
    this.appleLink = `https://maps.apple.com/maps?q=${this.business.publish.businessInformation.name}+${this.business.publish.businessInformation.address.address1}+${this.business.publish.businessInformation.address.city}+${this.business.publish.businessInformation.address.state}+${this.business.publish.businessInformation.address.postalCode}`;
    if (googleMapsIndex > -1) {
      this.mediaLinks.splice(googleMapsIndex, 1);
    }
    // check for ios devices
    // first condition checks for ios devices
    // second one for ipad 13
    // then check for IE
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (isIOS){
      this.addressLink = this.appleLink;
    }else{
      this.addressLink = this.googleLink;
    }

    const serviceOptionsDisplayNames = new Array<string>();
    this.business.publish.businessInformation.operationalStatus.forEach(serviceoption => {

      serviceOptionsDisplayNames.push(serviceoption.displayName);

    });
   /* SERVICE LIST, when new smb page is ready */

    // this.storeService.getStoreServices(false).subscribe( (services) => {
    //   if (services) {
    //     services.forEach( (service) => {
    //       this.serviceOptionsList.push({displayName: service.name, className: service.id});
    //     });
    //   }
    // });
    this.serviceOptionsString = serviceOptionsDisplayNames.join(', ');
  }

}
