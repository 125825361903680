<div
    class="socialMediaButtons"
    [ngClass]="horizontal ? 'socialButtonHorizontal' : 'socialButtonVertical'"
>
    <div [ngClass]="horizontal ? 'pulLButtonsRight' : ''">
        <a
            mat-flat-button
            class="icon-facebook"
            (click)="shareButtonClick('Facebook'); navigateToSocialMedia('Facebook')"
        ></a>

        <a
            mat-flat-button
            class="icon-twitter"
            (click)="shareButtonClick('Twitter'); navigateToSocialMedia('Twitter')"
        ></a>

        <a
            mat-flat-button
            class="icon-linkedin"
            (click)="shareButtonClick('LinkedIn'); navigateToSocialMedia('LinkedIn')"
        >
        </a>

        <a
            mat-flat-button
            class="icon-email"
            href="mailto: ?body={{ label | titlecase }}%0D%0A{{ url }}&subject= Check out {{
                isArticle == true
                    ? 'this article on staplesconnect.com'
                    : 'this business in the Staples Connect Community Business Directory'
            }}"
            (click)="shareButtonClick('Email')"
        ></a>

        <a
            mat-flat-button
            class="icon-printer"
            [ngClass]="{ 'horizontal-icon-printer': horizontal }"
            href="javascript:void(0)"
            (click)="onPrintClick()"
            (click)="shareButtonClick('Print')"
        ></a>

        <a
            mat-flat-button
            class="icon-copy-link"
            mattooltip="Copy page url"
            (click)="shareButtonClick('Clipboard'); navigateToSocialMedia('Clipboard')"
        >
        </a>
    </div>
</div>
