import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UtilsModule} from '@app/utils/utils.module';
import {RouterModule} from '@angular/router';
import { ButtonModule, GhostLoaderModule } from '@staples-connect/component-library';
import {EMLocationFlyOutComponent } from './em-location-fly-out/em-location-fly-out.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatIconModule } from '@angular/material/icon';
import {GoogleMapModule} from '@app/rde-components/google-map/google-map.module';
import {MaterialComponentsModule} from '@app/material-components.module';
import {EmGmPmMessageComponent} from '@app/cms-components/expand-menu/em-gm-pm-message/em-gm-pm-message.component';
import {GenericManagerCardComponent} from '@app/cms-components/expand-menu/generic-manager-card/generic-manager-card.component';
import { RdeComponentsModule } from '@app/rde-components/rde-components.module';

@NgModule({
    declarations: [
        EMLocationFlyOutComponent
    ],
    exports: [
        EMLocationFlyOutComponent,
    ],
    imports: [
        CommonModule,
        UtilsModule,
        RouterModule,
        ButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        GoogleMapModule,
        MaterialComponentsModule,
        GhostLoaderModule,
        RdeComponentsModule
    ]
})
export class ExpandMenuModule { }
