import { TYPE_CONTAINER_INLINE } from '@bloomreach/spa-sdk';
import { SideNavComponent } from '@app/cms-components/side-nav/side-nav.component';

import { DynamicLoaderComponent } from '@app/bre-page/dynamic-loader/dynamic-loader.component';

export class CMSMappings {
    static mapping = {
        [TYPE_CONTAINER_INLINE]: SideNavComponent,
        'InStore Services': DynamicLoaderComponent,
        // 'Header': HeaderComponent,
        'Editors Pick': DynamicLoaderComponent,
        'Ideas Banner': DynamicLoaderComponent,
        Article: DynamicLoaderComponent,
        'Blog Detail': DynamicLoaderComponent,
        'Article Pillars Grid': DynamicLoaderComponent,
        'Blog List': DynamicLoaderComponent,
        Footer: DynamicLoaderComponent,
        SEO: DynamicLoaderComponent,
        ErrorMessage: DynamicLoaderComponent,
        'Legal Page': DynamicLoaderComponent,
        'Fullscreen Informational Video Banner': DynamicLoaderComponent,
        'Marketing Banner': DynamicLoaderComponent,
        'Large Informational Block': DynamicLoaderComponent,
        'Generic Banner': DynamicLoaderComponent,
        'Small Card Grid': DynamicLoaderComponent,
        'Thin Informational banner': DynamicLoaderComponent,
        FAQ: DynamicLoaderComponent,
        'Service Banner': DynamicLoaderComponent,
        'Marketing Information List': DynamicLoaderComponent,
        'Service Offering List': DynamicLoaderComponent,
        'Service Highlight List': DynamicLoaderComponent,
        'Related Articles': DynamicLoaderComponent,
        'Product Brief': DynamicLoaderComponent,
        'Fullscreen Informational Image Banner': DynamicLoaderComponent,
        'BDS Homepage': DynamicLoaderComponent,
        'Product Badging': DynamicLoaderComponent,
        'Product Card Carousel': DynamicLoaderComponent,
        'Image Carousel': DynamicLoaderComponent,
        'Articles By Category Component': DynamicLoaderComponent,
        AlertBanner: DynamicLoaderComponent,
        'Curbside Pickup Order Detail Component': DynamicLoaderComponent,
        'RDE Carousel Component': DynamicLoaderComponent,
        'Video Banner': DynamicLoaderComponent,
        'Left Image Text Banner': DynamicLoaderComponent,
        '3 Featured Articles Component': DynamicLoaderComponent,
        'Smb Registry Business Details Page Component': DynamicLoaderComponent,
        'Blog Video Detail': DynamicLoaderComponent,
        'SMB Registry Businesses Near You Component': DynamicLoaderComponent,
        'Text Banner With Cta': DynamicLoaderComponent,
        'SMB Registry Form Component': DynamicLoaderComponent,
        'SMB Registry Form V2 Component': DynamicLoaderComponent,
        'Bopis Pickup Order Detail Component': DynamicLoaderComponent,
        'Product Vignette With Banner': DynamicLoaderComponent,
        PillarBannerWithBox: DynamicLoaderComponent,
        ThreeColumnContentComponent: DynamicLoaderComponent,
        OfferBlockTwoOffers: DynamicLoaderComponent,
        'Pro Services List': DynamicLoaderComponent,
        'Free Form Text Component': DynamicLoaderComponent,
        'Content Carousel': DynamicLoaderComponent,
        'Spacer Component': DynamicLoaderComponent,
        MenuGMMessageComponent: DynamicLoaderComponent,
        MenuStoreInformationComponent: DynamicLoaderComponent,
        'Community Banner': DynamicLoaderComponent,
        MenuExploreOurCommunityComponent: DynamicLoaderComponent,
        'Checkerboard Component': DynamicLoaderComponent,
        'EmbedCode Component': DynamicLoaderComponent,
        'IFrame Component': DynamicLoaderComponent,
        'Promo Block': DynamicLoaderComponent,
        'Single Coupon Component': DynamicLoaderComponent,
        'Online Circular Component': DynamicLoaderComponent,
        'Showroom Product Info Component': DynamicLoaderComponent,
        'Showroom Buy Button Component': DynamicLoaderComponent,
        'Showroom Cart Component': DynamicLoaderComponent,
        'Showroom Checkout Component': DynamicLoaderComponent,
        'Showroom Order Confirmation Component': DynamicLoaderComponent,
        'Showroom Order Confirmation Next Steps Component': DynamicLoaderComponent,
        'Single Video Module': DynamicLoaderComponent,
        'Brand Launch Hero Component': DynamicLoaderComponent,
        MultipleVideoModule: DynamicLoaderComponent,
        'Social Share Component': DynamicLoaderComponent,
        'Help Center Parent List Component': DynamicLoaderComponent,
        'Faq Component': DynamicLoaderComponent,
        'Loyalty Dashboard Component': DynamicLoaderComponent,
        'Screaming Offer Banner': DynamicLoaderComponent,
        'Help Center FAQ Component': DynamicLoaderComponent,
        'My Business Dashboard Component': DynamicLoaderComponent,
        'Help Center SideNav Component': DynamicLoaderComponent,
        Breadcrumb: DynamicLoaderComponent,
        'Contact Form': DynamicLoaderComponent,
        'Floating Action Button': DynamicLoaderComponent,
        AnchorTag: DynamicLoaderComponent,
        'Full Bleed Video': DynamicLoaderComponent,
        'TSA Service': DynamicLoaderComponent,
        'Location Component': DynamicLoaderComponent,
        StandaloneCTAComponent: DynamicLoaderComponent,
        MenuGMPMMessageComponent: DynamicLoaderComponent,
        'GenericIframe Embed Component': DynamicLoaderComponent,
        Oct2021PossibleLPComponent: DynamicLoaderComponent,
        Oct2021PossibleLPComponentAngular: DynamicLoaderComponent,
        'Print Marketing Service': DynamicLoaderComponent,
        'Trending Products': DynamicLoaderComponent,
        ArticlesV2: DynamicLoaderComponent,
        'Smb Registry Business Details Page V2 Component': DynamicLoaderComponent,
        'Small Business Directory Component': DynamicLoaderComponent,
        'Center Single Video Component': DynamicLoaderComponent,
        'Article Search Banner': DynamicLoaderComponent,
        'Articles By Pillar Component': DynamicLoaderComponent,
        'Product Selection Component': DynamicLoaderComponent,
        'Article Search List': DynamicLoaderComponent,
        'Count Down Component': DynamicLoaderComponent,
        'Free Form Text Carousel': DynamicLoaderComponent,
        'Hotspot Carousel Component': DynamicLoaderComponent,
        'Scheduler Check In Component': DynamicLoaderComponent,
        'Scheduler Status Component': DynamicLoaderComponent,
        'Scheduler Waitlist Component': DynamicLoaderComponent,
        'Large Center Image Block Component': DynamicLoaderComponent,
        'Two Column Timer Carousel': DynamicLoaderComponent,
        'Carousel With Slider': DynamicLoaderComponent,
        'Image Parallex Component': DynamicLoaderComponent,
        'Trending Product Carousel Component': DynamicLoaderComponent,
        'Recycle Component': DynamicLoaderComponent,
        'Store Specialist': DynamicLoaderComponent,
        'User Location Debugger': DynamicLoaderComponent,
        'Local Store Component': DynamicLoaderComponent,
        'Store Services Component': DynamicLoaderComponent,
        'Email Breach Search Component': DynamicLoaderComponent,
        'Grid Guide Debugger': DynamicLoaderComponent,
        'Single Use Coupon Component': DynamicLoaderComponent,
        'Weekly Ad Component': DynamicLoaderComponent,
        'Weekly Ad Promo Block Component': DynamicLoaderComponent,
        'Promo Banner': DynamicLoaderComponent,
        'Yeti Intake Form': DynamicLoaderComponent,
        BookAppointmentIFrame: DynamicLoaderComponent,
        'Clear Store Locator': DynamicLoaderComponent,
        'Printer Finder': DynamicLoaderComponent,
        'Services Breadcrumb': DynamicLoaderComponent,
        'Inline Coupon Component': DynamicLoaderComponent
    };
}
