import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixIdeasUrlPipe } from './pipes/fix-ideas-url/fix-ideas-url.pipe';
import { SafeurlPipe } from './safeurl.pipe';
import { YouTubeRelatedVideosPipe } from './you-tube-related-videos.pipe';
import { FormatEventTimingsPipe } from './pipes/format-event-timings/format-event-timings.pipe';
import { FormatVideoBannerYoutubeUrlPipe } from './format-video-banner-youtube-url.pipe';
import { FormatPhoneNumberPipe } from './format-phone-number/format-phone-number.pipe';
import { AkamaiImageSizePipe } from './pipes/akamai-image-size/akamai-image-size.pipe';
import { DecodeUrlPipe } from './pipes/decode-url/decode-url.pipe';
import { ReplaceCharactersPipe } from './replace-characters.pipe';
import { SuperScriptDirective } from './super-script.directive';
import { ObserveVisibilityDirective } from './observe-visibility-directive.directive';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { TrustUrlPipe } from './trust-url.pipe';
import { BarcodePipe } from './barcode/barcode.pipe';
import { AccountEyebrowPipe } from './pipes/account-eyebrow/account-eyebrow.pipe';
import { LazyLoadImgDirective } from '@app/utils/lazy-load-img.directive';
import { TrimPipe } from './trim-pipe.pipe';
import { StateFullNamePipe } from './state-name.pipe';
import { ThousandsValuePipe } from '@app/utils/thousands-value.pipe';
import { SuperScriptCentsDirective } from './super-script-cents.directive';

@NgModule({
    declarations: [
        FixIdeasUrlPipe,
        SafeurlPipe,
        YouTubeRelatedVideosPipe,
        FormatEventTimingsPipe,
        FormatVideoBannerYoutubeUrlPipe,
        FormatPhoneNumberPipe,
        AkamaiImageSizePipe,
        DecodeUrlPipe,
        ReplaceCharactersPipe,
        SuperScriptDirective,
        ObserveVisibilityDirective,
        ClickOutsideDirective,
        LazyLoadImgDirective,
        TrustUrlPipe,
        BarcodePipe,
        AccountEyebrowPipe,
        TrimPipe,
        StateFullNamePipe,
        ThousandsValuePipe,
        SuperScriptCentsDirective
    ],
    exports: [
        FixIdeasUrlPipe,
        SafeurlPipe,
        YouTubeRelatedVideosPipe,
        FormatEventTimingsPipe,
        FormatVideoBannerYoutubeUrlPipe,
        FormatPhoneNumberPipe,
        AkamaiImageSizePipe,
        DecodeUrlPipe,
        ReplaceCharactersPipe,
        SuperScriptDirective,
        ObserveVisibilityDirective,
        ClickOutsideDirective,
        LazyLoadImgDirective,
        TrustUrlPipe,
        BarcodePipe,
        AccountEyebrowPipe,
        TrimPipe,
        StateFullNamePipe,
        ThousandsValuePipe,
        SuperScriptCentsDirective
    ],
    imports: [CommonModule]
})
export class UtilsModule {}
