import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CustomerTracker} from '../../cms-components/curbside-pickup/order-status-page/curbside-pickup-customer-status-buttons/CustomerTracker';
import {BehaviorSubject, Observable} from 'rxjs';
import { CreateCartImpl} from '../../models/cart/create-cart';
import { v4 as uuid } from 'uuid';
import {ItemImpl, ShippingInfo} from '../../models/cart/CurbsideItem';
import {PickupPersonWrapper} from '../../models/cart/pickup-person';

@Injectable()
export class CurbsideService {

  private rootUrl = '/api/rcp/v1';
  private bopisRootUrl = '/api/bopis/smfp/staples/message';

  public cartSession = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  setOnyMyWay(body: CustomerTracker): Observable<any> {
    body.pickupPersonTrackingDetails.customerStatus = 'ON_MY_WAY';
    return this.http.put(this.rootUrl + '/orders/pickupCustomerTracker', body);
  }

  setImHere(body: CustomerTracker): Observable<any> {
    body.pickupPersonTrackingDetails.customerStatus = 'IM_HERE';
    return this.http.put(this.rootUrl + '/orders/pickupCustomerTracker', body);
  }

  createCart(storeNumber?: string): Observable<any> {
    const cartObj: CreateCartImpl = new CreateCartImpl(storeNumber);
    return this.http.post(this.rootUrl + '/carts', cartObj);
  }

  addItem(item: ItemImpl): Observable<any> {
    // @ts-ignore
    // gtag('event', 'add_to_cart', {
    //   items: [
    //     {
    //       id: item.productId,
    //       name: item.productInfo.name,
    //       brand: item.vendorName,
    //       category: 'Showroom/Dropship',
    //       variant: item.variant.vendorVariantId,
    //       //   list_position: 1,
    //       quantity: item.quantity,
    //       price: item.basePriceInfo.basePricePerUnit
    //     }
    //   ]
    // });
    const body = {
      items : [item],
      requestId: uuid()
    };

    return this.http.post(  `${this.rootUrl}/carts/${this.cartSession.getValue().id}/items`, body);
  }

  checkout(): Observable<any> {
    return this.http.post(this.rootUrl + `/checkout/${this.cartSession.getValue().id} `, {});
  }

  processCheckout(pickupPerson: PickupPersonWrapper) {
    pickupPerson.requestId = uuid();
    return this.http.post(this.rootUrl + `/checkout/${this.cartSession.getValue().id}/pickupPerson `, pickupPerson);

  }

  updateShippingInfo(checkoutId: string, fulfillmentGroupId: string, shippingInfo) {
    shippingInfo.requestId = uuid();
    return this.http.put(this.rootUrl + `/checkout/${checkoutId}/${fulfillmentGroupId}/shipmentDetails `, shippingInfo);
  }
  completeCheckout(cartId: string) {
    return this.http.post(this.rootUrl + `/checkout/${cartId}/completeCheckout `, {});

  }

  addCustomerNotes(note: string) {
    const customerNote = {
      customerProvidedNotes: note,
      requestId: uuid()
    };
    return this.http.post(this.rootUrl + `/checkout/${this.cartSession.getValue().id}/customerNotes `, customerNote);
  }

  deleteItemFromCart(itemId: string ) {
    return this.http.delete(`${this.rootUrl}/carts/${this.cartSession.getValue().id}/items/${itemId}`);
  }

  updateItemQuantity(itemId: string, qty: number) {
    const requestBody = {
      requestId: uuid(),
      items: [
      {
        cartItemId: itemId,
        quantity: qty
      }
    ]
    };
    return this.http.put(  `${this.rootUrl}/carts/${this.cartSession.getValue().id}/items`, requestBody);

  }

  // Bopis API call
  sendBopisNotification(storeNumber: number, orderNumber: string, eta: number) {
    // convert seconds to minutes
    eta = eta / 60;

    const  tdate = new Date();
    tdate.setMinutes(tdate.getMinutes() + eta);

    const body = {
      store_no: storeNumber,
      source_app: 'STAPLESCONNECT',
      message: `Customer on the way for order ${orderNumber}. ETA ${tdate.toTimeString().substring(0, 5)} `,
      message_type: 'ONTHEWAY',
      message_status: 'NEW',
      message_id: `${storeNumber}_` + Date.now(),
      order_no: orderNumber,
      pickup_time: tdate.toJSON()
    };

    return this.http.post(environment.siteDNSName + this.bopisRootUrl, body);
  }
}
