import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SEO } from '@app/models/SEO';
import { PageModel } from '@bloomreach/spa-sdk';
import { environment } from '@env/environment';

@Component({
    selector: 'rde-new-seo-component',
    templateUrl: './new-seo.component.html',
    styleUrls: ['./new-seo.component.scss']
})
export class NewSeoComponent implements OnInit {
    seo: SEO = null;
    isBrowser: boolean;
    pageComponentModel;
    seoComponent;
    currentURL = '';

    constructor(
        private titleService: Title,
        private meta: Meta,
        private router: Router,
        private transferState: TransferState,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject('BR_PAGE_MODEL_DATA') @Optional() private brPagemodelData?: PageModel
    ) {
        this.isBrowser = isPlatformBrowser(platformId);

        if (!this.isBrowser && brPagemodelData) {
            this.transferState.set(
                makeStateKey<PageModel>('BR_PAGE_MODEL_DATA_KEY'),
                brPagemodelData
            );
            this.pageComponentModel = brPagemodelData;
        }
        this.pageComponentModel =
            brPagemodelData || this.transferState.get(makeStateKey('BR_PAGE_MODEL_DATA_KEY'), null);

        this.seo = this.seoMapper(this.getSeoComponent(this.pageComponentModel));
        this.currentURL = this.pageComponentModel?._links?.self?.href;
    }

    ngOnInit() {
        // if we are serving an articles url, set title and description from articles data.
        if (
            this?.currentURL &&
            this.currentURL.includes(`/articles`) &&
            this.currentURL.endsWith('.html')
        ) {
            this.setArticlesSeo(this.pageComponentModel);
        }

        const seoData = this.seo;
        if (!seoData) {
            return;
        }
        this.titleService.setTitle(this.seo?.title);

        if (seoData && seoData.author) {
            this.meta.addTag({ name: 'author', content: seoData.author });
        } else {
            if (this.meta.getTag('author')) {
                this.meta.removeTag('author');
            }
        }

        const descriptionMetaInfo = {
            name: 'description',
            content: ''
        };
        if (seoData && seoData.description) {
            descriptionMetaInfo.content = seoData.description;
        } else if (seoData.description) {
            descriptionMetaInfo.content = seoData.description;
        }
        this.meta.updateTag(descriptionMetaInfo);
        //set og page description
        const ogDescriptionMetaInfo = {
            name: 'description',
            property: 'og:description',
            content: ''
        };
        if (descriptionMetaInfo.content) {
            ogDescriptionMetaInfo.content = descriptionMetaInfo.content;
        }
        this.meta.addTags([ogDescriptionMetaInfo]);

        // set keywords
        if (seoData && seoData.keywords) {
            this.meta.updateTag({ name: 'keywords', content: seoData.keywords });
        } else {
            if (this.meta.getTag('keywords')) {
                this.meta.removeTag('keywords');
            }
        }

        if (seoData && seoData.image) {
            let imageUrl = seoData.image;
            if (!seoData.image.startsWith('http')) {
                imageUrl = environment.siteDNSName + '/site' + seoData.image;
            }
            this.meta.updateTag({ name: 'image', property: 'og:image', content: imageUrl });
            this.meta.updateTag({ property: 'og:image:secure_url', content: imageUrl });
        } else {
            this.meta.updateTag({ property: 'og:image', content: '' });
            this.meta.updateTag({ property: 'og:image:secure_url', content: '' });
        }

        if (seoData && seoData.type) {
            this.meta.updateTag({ property: 'og:type', content: seoData.type });
        } else {
            this.meta.updateTag({ property: 'og:type', content: 'website' });
        }

        // prevent google from indexing non prod sites while they are not blocked by akamai;
        if (environment.noindex) {
            this.meta.updateTag({ name: 'robots', content: 'noindex' });
        } else {
            if (seoData && seoData.noindex) {
                this.meta.updateTag({ name: 'robots', content: 'noindex' });
            } else {
                this.meta.updateTag({ name: 'robots', content: 'index,follow' });
            }
        }

        this.meta.updateTag({
            property: 'og:url',
            content: environment.siteDNSNameStaples + this.router?.url
        });
    }

    private seoMapper(obj: any): SEO {
        if (!obj) {
            return null;
        }

        const seo = new SEO();

        seo.title = obj.siteTitle;
        seo.description = obj.defaultMetaDescription;
        seo.ogDescription = obj.defaultMetaOgDescription;
        seo.keywords = obj.defaultMetaKeywords;
        seo.noindex = obj.noindex;

        return seo;
    }

    private setArticlesSeo(pageModelData: any): any {
        if (!pageModelData) {
            return null;
        }
        const componentList = pageModelData?.page?.components?.find(value => {
            return value.name === 'main';
        });

        const componentContainer = componentList?.components[0]?.components.find(value => {
            return value.name === 'content';
        });

        if (!componentContainer) {
            return;
        }

        const contentID = componentContainer.models.document.$ref.split('/')[2];
        const content = pageModelData?.content[contentID];
        if (!content) {
            return;
        }

        if (content?.title !== '') {
            this.titleService.setTitle(`Articles | ${content.title}`);
        }

        if (content?.seoDescription !== '') {
            const descriptionMetaInfo = {
                name: 'description',
                property: 'description',
                content: content.seoDescription
            };
            this.meta.addTag(descriptionMetaInfo);
        }
    }

    private getSeoComponent(pageModelData): any {
        if (!pageModelData) {
            return null;
        }
        const componentList = pageModelData?.page?.components?.find(value => {
            return value.name === 'main';
        });

        let componentContainer = componentList?.components.find(value => {
            return value.name === 'container';
        });

        if (!componentContainer) {
            componentContainer = componentList;
        }

        const seoObj = componentContainer?.components?.find(value => {
            return value.label === 'SEO';
        });

        if (seoObj) {
            return seoObj?._meta?.paramsInfo;
        }

        return null;
    }
}
