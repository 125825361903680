<div class="back-button"><span class="material-icons icon navigation-control font-staples-red">
arrow_back
</span><a class="navigation-control font-staples-red" [href]="['/small-business/directory']">Return to Community Business Directory</a>
</div>
<div class="event-detail-header-row">
  <div class="event-detail-header-image-col">

    <ngb-carousel *ngIf="business.publish.businessInformation.akamaiUrls.length > 1">
      <ng-template ngbSlide *ngFor="let image of business.publish.businessInformation.akamaiUrls">
        <img [src]="image" alt="provided image" loading="lazy">
      </ng-template>
    </ngb-carousel>

    <img alt="business image" *ngIf="business.publish.businessInformation.akamaiUrls.length === 1" [src]="business.publish.businessInformation?.akamaiUrls[0]" class="header-image">

  </div>
  <div class="event-detail-header-info-wrapper">
    <div class="info-container">
      <h2 class="event-title bold">{{ business.publish.businessInformation.name }}</h2>
      <div class="grid">
        <div>
          <a class="font-staples-red" *ngIf="business.publish.businessInformation.websiteUrl" [href]="business.publish.businessInformation.websiteUrl" target="_blank">Visit website</a>
          <div class="info-item body-text-3">
            <a href="tel:{{business.publish.businessInformation.phoneNumber}}">{{business.publish.businessInformation.phoneNumber | formatPhoneNumber}}</a>
          </div>
          <div class="info-item body-text-3 social-row">
            <a  *ngFor="let link of mediaLinks"  class="social-button"
                [ngClass]="{'icon-facebook':link.site.toUpperCase() === 'FACEBOOK',
                      'icon-instagram':link.site.toUpperCase() === 'INSTAGRAM',
                      'icon-twitter':link.site.toUpperCase() === 'TWITTER',
                      'icon-yelp':link.site.toUpperCase()  === 'YELP',
                      'icon-trip-advisor': link.site.toUpperCase() === 'TRIP ADVISOR'}" [href]="link.siteUrl" target="_blank" ></a>

            <a *ngIf="business.publish.businessInformation.email" class="social-button icon-email" [href]="'mailto:' + business.publish.businessInformation.email" ></a>
          </div>
        </div>
        <div *ngIf="!business.publish.businessInformation.makeAddressPrivate">
          <div class="location-container">
            <div class="location-image-container">
              <img class="location-image" src="/assets/icon-location.svg" alt="location icon">
            </div>
            <div class="smaller-text">
              <div class="address-container">
                <a class="info-item body-text-3" [href]="addressLink" target="_blank">
                  {{business.publish.businessInformation?.address?.address1}} {{business.publish.businessInformation?.address?.address2}}</a>
                <a class="info-item body-text-3" [href]="addressLink" target="_blank">{{business.publish.businessInformation?.address?.city}}, {{business.publish.businessInformation?.address?.state}} {{business.publish.businessInformation?.address?.postalCode}}</a>
                <a class="font-staples-red body-text-3" [href]="addressLink" target="_blank">View on map</a>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="business.publish.businessInformation?.showHours !== 'HIDE'">
          <p class="bold">Hours of Operation</p>
          <div *ngIf="business.publish.businessInformation?.showHours === 'SHOW' || !business.publish.businessInformation?.showHours">
            <p class="body-text-3 store-hours">Mon:
              {{business.publish.businessInformation.businessHours.monday |formatSmbRegistryBusinessHours}}</p>
            <p class="body-text-3 store-hours">Tue:
              {{business.publish.businessInformation.businessHours.tuesday |formatSmbRegistryBusinessHours}}</p>
            <p class="body-text-3 store-hours">Wed:
              {{business.publish.businessInformation.businessHours.wednesday |formatSmbRegistryBusinessHours}}</p>
            <p class="body-text-3 store-hours">Thu:
              {{business.publish.businessInformation.businessHours.thursday |formatSmbRegistryBusinessHours}}</p>
            <p class="body-text-3 store-hours">Fri:
              {{business.publish.businessInformation.businessHours.friday |formatSmbRegistryBusinessHours}}</p>
            <p class="body-text-3 store-hours">Sat:
              {{business.publish.businessInformation.businessHours.saturday |formatSmbRegistryBusinessHours}}</p>
            <p class="body-text-3 store-hours">Sun:
              {{business.publish.businessInformation.businessHours.sunday |formatSmbRegistryBusinessHours}}</p>
          </div>
          <div *ngIf="business.publish.businessInformation?.showHours === 'TEMPCLOSED'">Temporarily Closed</div>
        </div>
        <div>
          <p class="bold" style="display: block">Service options</p>
          <p class="body-text-3">{{serviceOptionsString}}</p>
          <p class="bold business-category">Category</p>
          <p class="body-text-3">{{business.publish.businessInformation.category.displayName}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
