import { Component, Inject, OnInit, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { SharedUtilsService } from '../../utils/shared-utils/shared-utils.service';
import { StoreService } from '../../services/store/store.service';
import { DataService } from '../../services/data-service/data.service';
import { isPlatformBrowser } from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {UserLocationService} from '../../services/user-location-service/user-location.service';

@Component({
  selector: 'rde-nav-location-fly-out',
  templateUrl: './nav-location-fly-out.component.html',
  styleUrls: ['./nav-location-fly-out.component.scss']
})
export class NavLocationFlyOutComponent implements OnInit {
  @ViewChild('searchField', {static: false}) searchField: ElementRef<any>;
  private isBrowser: boolean;
  state = 'ma'.toLowerCase();
  city = 'framingham'.toLowerCase();
  street = '659-worcester-rd'.toLowerCase();
  storeDetailsLink: string = 'https://stores.staples.com/' + this.state + '/' + this.city + '/' + this.street;
  nearByStoreInfo: any;
  storeShortAddress = '';
  storeTimingStatus: any;
  storeTimings: any;
  stores: any = [];
  storesNotFoundMessage = '';



  constructor(private sharedUtilsService: SharedUtilsService,
              private userLocationService: UserLocationService,
              private storeService: StoreService,
              private dataService: DataService,
              private cookieService: CookieService,
              @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.dataService.selectedStore.subscribe( store => {
      this.nearByStoreInfo = store;
      this.storeTimingStatus = this.sharedUtilsService.storeOpeningStatus(this.nearByStoreInfo.storeWorkingHours, 'America/New_York');
    });

    this.dataService.nearByStores.subscribe( nearByStores => {
      this.stores = nearByStores;
    });

  }

  searchLocation(event) {
    if (this.searchField.nativeElement.value && (event.keyCode === 13 || event.relatedTarget === null)) {
      this.storeService.searchStoresByValue(this.searchField.nativeElement.value).subscribe((storesList: any) => {
        this.stores = [];
        if (storesList.length >0) {
          this.searchField.nativeElement.value = '';
          this.stores = storesList;
        }
       else if (storesList.length == 0) {
          this.storesNotFoundMessage = "No results found within 150 miles of zipcode ";
        }
        else {
            this.storesNotFoundMessage = "Sorry, no stores matched your search ";
        }

      });
    }
  }

  searchLocationInMobile(event) {
    if (event.relatedTarget === null && this.searchField.nativeElement.value && window.outerWidth < 767.98) {
      this.searchLocation(event);
    }
  }

  onSetMyStoreClick(storeInfo) {
    const expire = new Date();
    expire.setFullYear(expire.getFullYear() + 1);
    this.cookieService.set('scUserSelectedStore', JSON.stringify({ storeNumber: storeInfo.storeNumber, lat: storeInfo.location.y, lng: storeInfo.location.x }), expire);
    if (Object.keys(storeInfo)) {
      this.userLocationService.getNearByStores(storeInfo.location.y, storeInfo.location.x);
    }
  }

  useCurrentLocation() {
    this.storesNotFoundMessage= ""
    const userLoc = this.dataService.userLocation.getValue();
    this.storeService.getNearByStoresList(userLoc.lat, userLoc.lng, 150, 3).subscribe( (storesList: any) => {
      if (storesList.length) {
        this.searchField.nativeElement.value = '';
        this.stores = storesList;
      }

    });
  }
}
