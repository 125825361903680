import { ErrorHandler, NgModule } from '@angular/core';
import {PlatformModule} from '@angular/cdk/platform';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BASE_URL, BrePageComponent, ENDPOINT } from './bre-page/bre-page.component';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { UtilsModule } from './utils/utils.module';
import { NavLocationFlyOutComponent } from './cms-components/nav-location-fly-out/nav-location-fly-out.component';
import { CookieService } from 'ngx-cookie-service';
import { RdeComponentsModule } from './rde-components/rde-components.module';
import { CurbsidePickupModule } from './cms-components/curbside-pickup/curbside-pickup.module';
import {
    ButtonModule,
    CheckboxModule,
    GhostLoaderModule,
    RadioModule,
    ScAnimationsModule,
    ScCarouselModule,
    ScFormFieldModule,
    ScImageCropperModule,
    ScInputModule,
    ScSelectModule,
    TimerModule,
    ValidatorUtils
} from '@staples-connect/component-library';
import {
    APP_BASE_HREF,
    AsyncPipe,
    CommonModule,
    DatePipe,
    SlicePipe,
    TitleCasePipe
} from '@angular/common';
import { CarouselSliderModule } from '@app/rde-components/carousel-slider/carousel-slider.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GoogleMapModule } from '@app/rde-components/google-map/google-map.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatStepperModule } from '@angular/material/stepper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { IdeasService } from '@app/services/ideas/ideas.service';
import { CurbsideService } from '@app/services/curbside/curbside.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from '@app/utils/http-interceptor/http-interceptor.service';
import { SafeurlPipe } from '@app/utils/safeurl.pipe';
import { environment } from '@env/environment';
import { RouterModule } from '@angular/router';
import { RDECarouselModule } from '@app/cms-components/rdecarousel/rdecarousel.module';
import { IdeasArticlePageModule } from '@app/cms-components/ideas-article-page-component/ideas-article-page.module';
import { OfferBlockTwoOffersModule } from '@app/cms-components/offer-block-two-offers/offer-block-two-offers.module';
import { RewardsSignupComponent } from '@app/cms-components/rewards-signup/rewards-signup/rewards-signup.component';
import { ServiceOfferingListModule } from '@app/cms-components/service-offering-list-wrapper/service-offering-list.module';
import { SmallBusinessRegistryModule } from '@app/cms-components/small-business-registry/small-business-registry.module';
import { ExpandMenuModule } from '@app/cms-components/expand-menu/expand-menu.module';
import { CheckerboardPromoModule } from '@app/cms-components/checkerboard-promo/checkboard-promo.module';
import { ShowroomModule } from '@app/cms-components/showroom/showroom.module';
import { FormatSelfServiceHoursPipe } from '@app/cms-components/self-serve/format-self-service-hours.pipe';
import { TimePipe } from '@app/cms-components/self-serve/timePipe';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FormatSmbRegistryBusinessHoursPipe } from '@app/cms-components/small-business-registry/format-smb-registry-business-hours.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DynamicLoaderComponent } from '@app/bre-page/dynamic-loader/dynamic-loader.component';
import { DynamicCtaModule } from './rde-components/dynamic-cta/dynamic-cta.module';
import { DynamicHTagModule } from './cms-components/dynamic-h-tag/dynamic-h-tag.module';
import { ReplaceCharactersPipe } from '@app/utils/replace-characters.pipe';
import { RecycleService } from '@app/services/recycle.service';
import { APP_ID } from '@angular/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { GaTrackerDirective } from './ga-tracker.directive';
import { GlobalErrorHandler } from '@app/utils/global-error-handler';
import { SideNavComponent } from './cms-components/side-nav/side-nav.component';
import { ImageCropComponent } from './cms-components/image-crop/image-crop.component';
import { MatIconModule } from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import { NewSeoComponent } from '@app/cms-components/new-seo-component/new-seo.component';
import { provideClientHydration } from '@angular/platform-browser';
import { BookAppointmentIframeComponent } from './cms-components/book-appointment-iframe/book-appointment-iframe.component';


@NgModule({
    declarations: [
        AppComponent,
        BrePageComponent,
        DynamicLoaderComponent,
        RewardsSignupComponent,
        SideNavComponent,
        ImageCropComponent,
        NavLocationFlyOutComponent,
        NewSeoComponent,
        BookAppointmentIframeComponent
    ],
    imports: [
        RouterModule,
        AppRoutingModule,
        BrSdkModule,
        BrowserAnimationsModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.threeBounce,
            backdropBackgroundColour: 'rgba(0,0,0,0.4)',
            backdropBorderRadius: '6px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        UtilsModule,
        MatIconModule,
        MatListModule,
        MatFormFieldModule,
        MatDialogModule,
        MatExpansionModule,
        RdeComponentsModule,
        CurbsidePickupModule,
        CommonModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleMapModule,
        CarouselSliderModule,
        ImageCropperModule,
        MatStepperModule,
        MatAutocompleteModule,
        NgbCarouselModule,
        MatAutocompleteModule,
        NgxMaskDirective,
        NgxMaskPipe,
        ButtonModule,
        RDECarouselModule,
        IdeasArticlePageModule,
        OfferBlockTwoOffersModule,
        ServiceOfferingListModule,
        SmallBusinessRegistryModule,
        ExpandMenuModule,
        ScAnimationsModule,
        CheckerboardPromoModule,
        GhostLoaderModule,
        ScInputModule,
        ShowroomModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ScFormFieldModule,
        ScInputModule,
        ScSelectModule,
        RadioModule,
        CheckboxModule,
        MatPaginatorModule,
        ScImageCropperModule,
        TimerModule,
        DynamicHTagModule,
        DynamicCtaModule,
        ScCarouselModule,
        GaTrackerDirective,
        PlatformModule
    ],

    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: APP_ID, useValue: 'brPage' },
        provideNgxMask(),
        IdeasService,
        CurbsideService,
        DatePipe,
        TitleCasePipe,
        CurbsideService,
        CookieService,
        SafeurlPipe,
        AsyncPipe,
        FormatSmbRegistryBusinessHoursPipe,
        FormatSelfServiceHoursPipe,
        TimePipe,
        ValidatorUtils,
        ReplaceCharactersPipe,
        SlicePipe,
        { provide: BASE_URL, useValue: environment.baseUrl },
        { provide: ENDPOINT, useValue: environment.endpoint },
        RecycleService
    ],
    exports: [NavLocationFlyOutComponent, GaTrackerDirective],
    bootstrap: [AppComponent]
})
export class AppModule {}
