import {Component, OnInit, Input, ElementRef, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'rde-businesses-by-city',
  templateUrl: './businesses-by-city.component.html',
  styleUrls: ['./businesses-by-city.component.scss']
})
export class BusinessesByCityComponent implements OnInit, AfterViewInit {
  @Input() city: any;
  @Input() state: any;
  public numberOfRow: any;
 // public items = [];
  public contentList: any;
  public offset = 0;
  public eventsCountLeft = 0;
  public EventBadgeLabel = 'Free';
  private showTwoEvents = false;

  constructor(private el: ElementRef,
              private breakpointObserver: BreakpointObserver,
              private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.offset = this.contentList.length > 1 ? 1 : this.contentList.length;
        //  this.showTwoEvents = true;
          this.loadMoreContent(this.offset);
        } else {
          if (this.contentList) {
            this.offset = this.contentList.length > 2 ? 2 : this.contentList.length;
            this.showTwoEvents = false;
            this.loadMoreContent(this.offset);
          }
        }
      }
    );
  }

  ngAfterViewInit() {
    this.contentList = Array.prototype.slice.call(this.el.nativeElement.querySelectorAll('a.event-card'));
    this.offset = this.contentList.length > 2 ? 2 : this.contentList.length;
    this.loadMoreContent(this.offset);
  }

  loadMoreContent(offset) {
    this.contentList.forEach((elm, index) => {
      elm.classList.remove('display-event-card');

      if (index <= offset) {
        elm.classList.add('display-event-card');
      }
    });

    this.eventsCountLeft = this.el.nativeElement.querySelectorAll('a.event-card:not(.display-event-card)').length;
    if (this.el.nativeElement.querySelectorAll('a.event-card:not(.display-event-card)').length === 0) {
      this.el.nativeElement.querySelector('a.load-more-button').style.visibility = 'hidden';
    }
  }

  onLoadMoreClick(e) {
    e.preventDefault();

    const eventsPerRow = this.showTwoEvents ? 2 : 3;

    this.offset = this.contentList.length > this.offset + eventsPerRow ? this.offset + eventsPerRow : this.contentList.length;
    this.loadMoreContent(this.offset);
  }
}
