import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'rde-social-media-button-list',
    templateUrl: './social-media-button-list.component.html',
    styleUrls: ['./social-media-button-list.component.scss']
})
export class SocialMediaButtonListComponent implements OnInit {
    @Input() horizontal = true;
    @Input() redirectURL = '';
    @Input() pageLabel = '';
    @Input() isArticle = true;
    @Input() businessName: string;

    private isBrowser: boolean;
    url: string;
    label: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private clipboard: Clipboard,
        private snackBar: MatLegacySnackBar
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.matIconRegistry.addSvgIcon(
            'linkedin',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/linkedin-grey.svg')
        );
    }

    ngOnInit() {
        if (this.isBrowser) {
            this.label = this.pageLabel
                ? this.pageLabel
                : location.pathname
                      .split('/')
                      [location.pathname.split('/').length - 1].replace('.html', '')
                      .replace(/-/g, ' ');
            this.url = this.redirectURL ? this.redirectURL : location.href;
        }
    }

    onPrintClick() {
        if (this.isBrowser) {
            window.print();
        }
    }

    navigateToSocialMedia(media: string) {
        if (media === 'Facebook') {
            window.open('https://www.facebook.com/sharer.php?u=' + window.location.href);
        }

        if (media === 'Twitter') {
            window.open('https://twitter.com/share?url=' + window.location.href);
        }

        if (media === 'LinkedIn') {
            window.open(
                'https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.href
            );
        }
        if (media === 'Clipboard') {
            this.copyToClipboard(window.location.href);
        }
    }
    shareButtonClick(button: string) {
        // @ts-ignore
        // gtag('event', 'share', { method: button });

        if (button === 'Facebook') {
            // @ts-ignore
            dataLayer.push({
                event: 'articlesFacebookShare',
                eventCategory: 'Articles',
                eventAction: 'facebook share',
                eventLabel: this.businessName
            });
        }

        if (button === 'Twitter') {
            // @ts-ignore
            dataLayer.push({
                event: 'articlesTwitterShare',
                eventCategory: 'Articles',
                eventAction: 'twitter share',
                eventLabel: this.businessName
            });
        }

        if (button === 'Email') {
            // @ts-ignore
            dataLayer.push({
                event: 'articlesEmailShare',
                eventCategory: 'Articles',
                eventAction: 'email share',
                eventLabel: this.businessName
            });
        }


        if (button === 'LinkedIn') {
            // @ts-ignore
            dataLayer.push({
                event: 'articlesLinkedInShare',
                eventCategory: 'Articles',
                eventAction: 'LinkedIn share',
                eventLabel: this.businessName
            });
        }
        if (button === 'Clipboard') {
            // @ts-ignore
            dataLayer.push({
                event: 'articlesClipboardCopy',
                eventCategory: 'Articles',
                eventAction: 'Clipboard click',
                eventLabel: this.businessName
            });
        }
        if (button === 'Print') {
            // @ts-ignore
            dataLayer.push({
                event: 'articlesPrint',
                eventCategory: 'Articles',
                eventAction: 'Print articles',
                eventLabel: this.businessName
            });
        }
    }

    copyToClipboard(text: string) {
        const copied = this.clipboard.copy(text);
        if (copied) {
            this.snackBar.open('Copied to clipboard!', 'Close', { duration: 3000 });
        } else {
            this.snackBar.open('Error coping to clipboard!', 'Close', { duration: 3000 });
        }
    }
}
